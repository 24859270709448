import React, { useEffect, useState } from 'react'
import { Box, Typography, CardHeader, Stack, Grid, Button, Divider } from '@mui/material';
import { AccountBalance, Add, Delete, Edit, FamilyRestroom, } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';
import SharedDrawer from '../../features/drawers/Drawer';
import AddBankDrawer from '../../components/AdminDrawers/AddBankDrawer';
import { openDrawer } from '../../features/drawers/drawerSlice';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BankDetail = ({ readOnly }) => {
    const [openBank, setOpenBank] = useState(false)
    const [accordionOpen, setAccordionOpen] = useState(false);

    const dispatch = useDispatch();
    const openBankDrawer = () => {
        dispatch(openDrawer('bank'));
    }
    const userBankDetails = useSelector((state) => state?.user?.userListName?.userBankDetails ?? []);
    console.log("a1", userBankDetails)
    const setValues = () => {
        if (userBankDetails?.length) {
            bankDetailsHelper.set({
                'Bank Name': userBankDetails[0]["bankName"],
                'Branch Address': userBankDetails[0]["addressOfBranch"],
                'Account No.': userBankDetails[0]['accountNo'],
                'IFSC Code': userBankDetails[0]['ifscCode'],
                'UAN No.': userBankDetails[0]["uanNo"],
                'PF No.': userBankDetails[0]['pfNo'],
            }, () => {
                console.log("Jiren Done")
            })
        }
    }
    useEffect(function () {
        setValues();
        window.scrollTo(0, 0);
    }, [userBankDetails])
    useEffect(function () {
        setValues();
        window.scrollTo(0, 0);
    }, [])
    let inputProps = { readOnly: true };
    let bankDetailModel = [
        { inputProps: inputProps, label: 'Bank Name', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }, { name: 'length', min: 5 }], },
        { inputProps: inputProps, label: 'Branch Address', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], },
        { inputProps: inputProps, label: 'Account No.', type: "textField", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], },
        { inputProps: inputProps, label: 'IFSC Code', type: "textField", value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], },
        { inputProps: inputProps, label: 'UAN No.', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], variant: "outlined", },
        { inputProps: inputProps, label: 'PF No.', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }], },
    ];
    const bankDetailsHelper = new FormHelper({ model: bankDetailModel, id: 'personalDetails' });

    const openBankTextfield = () => {
        setOpenBank(!openBank)
    }
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setAccordionOpen(isExpanded);
    };
    return (
        <Box>
            <Box >
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Bank Details</Typography>
                    </Box>
                </Stack>
                <Card sx={openBank ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                    <Accordion expanded={accordionOpen} onChange={handleAccordionChange("panel1")}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <CardHeader
                                sx={{ pl: 1 }}
                                avatar={<AccountBalance color={accordionOpen?'primary':""}/>}
                                subheader="Please fill your account number details"
                                title={<Typography variant='body1' fontWeight="var(--font-weight-5)" >My Bank Details</Typography>}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            {openBank ?
                                <AddBankDrawer parentId='bank' drawerHeading={"Add Bank Details"} setOpenBank={setOpenBank} openBank={openBank}/>
                                :
                                <>
                                    {userBankDetails.length == 0 && (
                                        <Box sx={{ px: 2, py: 1 }}>
                                            <Box textAlign={"center"} onClick={openBankTextfield} >
                                                <Button startIcon={<Add />} variant='outlined' > ADD BANK DETAILS</Button>
                                            </Box>
                                        </Box>

                                    )}
                                </>
                            }

                            {userBankDetails.length > 0 && userBankDetails?.map((bank, key) => {
                                // console.log('a1',user?.userListName?.userBankDetails)
                                return (<>
                                    <Box sx={{ m: 2 }}>
                                        <Box><Typography variant="subtitle1" color="text.primary">Bank {key + 1}</Typography> </Box>
                                        <Box sx={{ my: '1rem' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} md={4}>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.secondary">
                                                            Bank Name
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {bank.bankName}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.secondary">
                                                            Branch Address
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {bank.addressOfBranch}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.secondary">
                                                            Account No.
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {bank.accountNo}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.secondary">
                                                            IFSC Code
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {bank.ifscCode}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.secondary">
                                                            UAN No.
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {bank.uanNo}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.secondary">
                                                            PF No.
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {bank.pfNo}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider sx={{ my: 2 }} />
                                    </Box>
                                </>
                                )
                            })}
                            {/* </CardContent> */}
                        </AccordionDetails>
                    </Accordion>
                </Card>
                {/* <SharedDrawer id="bank">
                    <AddBankDrawer parentId='bank' drawerHeading={"Add Bank Details"} />
                </SharedDrawer> */}
            </Box>
        </Box>
    )
}

export default BankDetail