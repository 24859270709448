import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { loginHandler, ssoLoginHandler ,loginOtpHandler} from "auth-component"

import '../../assets/styles/Login.css';
import logo from "../../assets/images/logo.png";
import TransitionsSnackbar from '../../features/snakebar/Snackbar';
import { setSnackBar } from '../../features/snakebar/snakebar';
import { useDispatch } from 'react-redux';
import { getCaptchaSetting } from "./getCaptchaSetting";
import ReCAPTCHA from 'react-google-recaptcha';
import { MuiOtpInput } from 'mui-one-time-password-input'

// import SSODailog from './Modal/SsoDailog';
// import  TeamsSSOLoginHandler from '../_services/TeamsSSOLoginHandler';

function Login(props) {
	const [showPassword, setShowPassword] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [open, setOpen] = useState(false);
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState("");
	const [emailErr, setEmailErr] = useState(false);
	const [passErr, setPassErr] = useState(false);
	const [showCaptcha, setShowCaptcha] = useState(true);
	const [captchaSiteKey, setCaptchaSiteKey] = useState("");
	const [reCaptchaValue, setReCaptchaValue] = useState(false);
	const [captchaError, setCaptchaError] = useState(false);
	const [otpFormStatus, setOtpFormStatus] = useState(false);
	const [currentLoginEmail, setCurrentLoginEmail] = useState("");
	const [otpErrorMessage, setOtpErrorMessage] = useState("");
	

	const [otp, setOtp] = React.useState('')

	const recaptchaRef = useRef();

	const dispatch = useDispatch();
	var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	const [disabled, setDisabled] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleChange = (newValue) => {
		setOtp(newValue)
	}
	function matchIsNumeric(text) {
		const isNumber = typeof text === 'number';
		const isString = typeof text === 'string'; // Check if the value is a string
		return (isNumber || (isString && text !== '')) && !isNaN(Number(text));
	}

	const validateChar = (value, index) => {
		return matchIsNumeric(value);
	}


	const handleClose = () => {
		setOpen(false);

	};
	const handleRecaptchaChange = (value) => {
		// Use the reCAPTCHA response value (token) here
		// console.log("ReCAPTCHA response:", value);
		setReCaptchaValue(value);
	};
	useEffect(() => {
		console.log("Hitt")
		getCaptchaSetting().then(response => {
			console.log({ response })
			let data = response.data.data;
			if (data) {
				setShowCaptcha(data?.[0]?.captcha?.activeStatus == '1');
				setCaptchaSiteKey(data?.[0]?.captcha?.siteKey)
				console.log({ siteKey: data?.[0]?.captcha?.siteKey })
			}
		}).catch((e) => {
			console.log({ e })
		})
	}, [])
	async function loginClickHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		let postData = {};
		if (showCaptcha) {
			if (reCaptchaValue) {
				setCaptchaError(false);
				postData['g-recaptcha-response'] = reCaptchaValue;
			} else {
				setCaptchaError(true);
				return;
			}
			// postData['captcha'] = true;
		}
		if (mailformat.test(email)) {
			postData.email = email;
			setEmailErr(false);
		} else {
			setEmailErr(true);
		}
		if (password) {
			postData.password = password;
			setPassErr(false);
		} else {
			setPassErr(true);
		}
		if (email && password) {
			setDisabled(true);
			loginHandler(postData).then(r => {
				setDisabled(false);
				console.debug("loginHandler:r", r)
				if (r.error) {
					console.debug("loginHandler:r", r)
				} else {
					console.debug("loginHandler:r", r)
					if (r.twoFaStatus) {
						setOtpFormStatus(true)
						setCurrentLoginEmail(r.email)
					} else if (r.twoFaStatus == false) {
						console.debug("twoFaStatus is false", r)
						if (r.redirectUrl && r.redirectUrl != null) {
							window.location.assign(r.redirectUrl)
						} else {
							console.debug("loginHandler:r", r)
							window.location.assign("/")
						}
					} else {
						console.error("loginHandler : Something went wrong ");
					}
				}
			}).catch(e => {
				console.debug("loginHandler:r", e)
				// handleClickOpen()
				dispatch(setSnackBar({ dataLoad: true, message: e.message, severity: "error" }))
				console.debug("ssoLoginHandler : response : ", e);
				setDisabled(false);
			});
		}
	}
	async function otpClickHandler(e) {
		e.preventDefault();
		let postData = {};
		if (email) {
			postData.email = email;
		}
		if (otp) {
			postData.otp = otp;
		}

		if (email && otp) {
			console.debug("Otp Api calling")
			loginOtpHandler(postData).then(r => {
				console.debug("loginHandler:r", r)
				
					console.debug("loginHandler:r", r)
					if (r.redirectUrl != null) {
						window.location.assign(r.redirectUrl)
					} else {
						console.debug("loginHandler:relse error", r)
						window.location.assign("/")
					}

			}).catch(e => {
				// handleClickOpen()
				console.debug("loginHandler : catch : ", e);
				setOtpErrorMessage(e.message)
			});
		}
	}
	const formInputChangeHandler = (e) => {
		e.preventDefault();
		if (e.target.name == "email") {
			if (mailformat.test(e.target.value)) {
				setEmail(e.target.value);
				setEmailErr(false)
				setErrorMsg('');
			} else {
				setEmail(e.target.value);
				setEmailErr(true)
			}
		}
		if (e.target.name == "password") {
			setPassword(e.target.value);
			setErrorMsg('');
		}
	}

	// async function ssoLoginClickHandler(e) {
	// 	e.preventDefault();
	// 	console.debug("ssoLoginClickHandler : start");

	// 	TeamsSSOLoginHandler().then(r=>{
	// 		console.debug("ssoLoginClickHandler : TeamsSSOLoginHandler : r : ",r);
	// 		if(r){
	// 			if(r.preferredUserName){
	// 				console.error("ssoLoginClickHandler : TeamsSSOLoginHandler : If Block : r.preferredUserName : ",r.preferredUserName);
	// 				// handleClickOpen();
	// 			}
	// 			if(r.redirectUrl){
	// 				console.debug("ssoLoginClickHandler : TeamsSSOLoginHandler : If Block : r.redirectUrl : ",r.redirectUrl);
	// 				window.location.assign(r.redirectUrl); 
	// 			}
	// 		}
	// 	}).catch(e=>{
	// 		console.error("ssoLoginClickHandler : TeamsSSOLoginHandler : e : ",e);
	// 		// handleDialogStatus()
	// 		//     if(!error.detail){
	// 		//         error.detail='Something went wrong'
	// 		//     }
	// 		// setDailogMessage(error.detail);
	// 	})
	// }

	return (
		<Grid container justifyContent="center" alignItems="center" style={{ height: '100vh', background: '#E9EAED' }}>
			<Grid item xs={12} sm={8} md={6} lg={4}>
				{/* <Paper elevation={3} sx={{ padding: 3 }}> */}
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<a href="/login"><img src={logo} alt="logo" /></a>
					{/* <LockOutlinedIcon color="primary" sx={{ fontSize: '48px' }} /> */}

				</Box>

				{otpFormStatus ? <Box x={{ mt: 1, display: "flex", justifyContent: "center" }}>
					<Typography variant="h5" sx={{ mt: 1, display: "flex", justifyContent: "center" }} >
						<h3 className="Login_card">Enter  verification code </h3>
					</Typography>
					<Typography variant="h7" sx={{ mt: 1 }}>
						We have  just sent  a  verification code to {currentLoginEmail}
					</Typography>
					<Box sx={{ mt: 4 }}>
						<MuiOtpInput length={6} value={otp} onChange={handleChange} type="number" validateChar={validateChar} />
						<Typography color="error" sx={{mt:2}}>{otpErrorMessage}</Typography>
					</Box>
					<Button type="submit" variant="contained" style={{
						background: ' #EC7C25',
						border: '1px solid #fcb116'
					}} onClick={otpClickHandler} fullWidth sx={{ mt: 3 }} disabled={disabled} >
						Verify
					</Button>
				</Box>
					:
					<Box>
						<Typography variant="h5" component="div" sx={{ mt: 1 }}>
							<h2 className="Login_card">Don't have an account? <a href="/register" className="btn-link" >Sign up</a></h2>
						</Typography>
						{/* <form> */}
						<FormControl fullWidth sx={{ mt: 2 }}>
							<TextField
								id="email"
								type="email"
								label="Email address"
								variant="outlined"
								// value={email}
								name="email"
								// inputRef={email}
								error={emailErr}
								helperText={emailErr ? "Please enter a valid email account" : ""}
								onChange={(e) => formInputChangeHandler(e)}
								//   onChange={(e) => setEmail(e.target.value)}
								autoFocus
								required
							/>
						</FormControl>
						<FormControl fullWidth sx={{ mt: 2 }}>
							<TextField
								id="password"
								type={showPassword ? "text" : "password"}
								label="Password"
								variant="outlined"
								// value={password}
								name="password"
								// inputRef={password}
								error={passErr}
								helperText={passErr ? "Please enter password " : ""}
								onChange={(e) => formInputChangeHandler(e)}
								//   onChange={(e) => setPassword(e.target.value)}
								required
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Button style={{ color: '#1BA1CB', fontSize: "700" }} onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</Button>
										</InputAdornment>
									),
								}}
							/>
						</FormControl>
						{errorMsg}
						<FormControl fullWidth sx={{ mt: 2 }}>
							<Box className="form-group text-left">
								<a className="btn-link" style={{ textDecoration: 'none' }} href="/forgot-password">
									Forgot Password?
								</a>
								<a className="btn-link text-right restButton" style={{ textDecoration: 'none' }} id="show-password-ref" href="/reset-password">
									Reset Password
								</a>
							</Box>
						</FormControl>
						<FormControlLabel
							control={<Checkbox id="defaultLoginFormRemember" />}
							label="Keep me signed in"
							sx={{ mt: 2 }}
						/>
						{/* <TextField type="checkbox" className="custom-control-TextField" id="defaultLoginFormRemember" />
					<label className="custom-control-label keenlabel" htmlFor="defaultLoginFormRemember">Keep me signed in</label> */}
						{showCaptcha && captchaSiteKey != "" && (<div className="form-group">
							<ReCAPTCHA
								ref={recaptchaRef}
								sitekey={captchaSiteKey}
								onChange={handleRecaptchaChange}
							/>
							{captchaError && (<Box sx={{ fontweight: 400, fontSize: '0.75rem', lineHeight: '1.66', letterSpacing: '0.03333em', textAlign: 'left', margin: '3px 14px 0px 14px', color: '#d32f2f' }}>
								Complete the reCAPTCHA verification to proceed.
							</Box>)}
						</div>)}
						<Button type="submit" variant="contained" style={{
							background: ' #EC7C25',
							border: '1px solid #fcb116'
						}} onClick={loginClickHandler} fullWidth sx={{ mt: 3 }} disabled={disabled} >
							Login
						</Button>
					</Box>}





				{/* <Button type="submit" variant="contained" style={{
						background: ' #EC7C25',
						border: '1px solid #fcb116'
					}} onClick={ssoLoginClickHandler} fullWidth sx={{ mt: 3 }}>
						SSO Login
					</Button>
					<SSODailog open={open} handleClose={handleClose} /> */}
				{/* </form> */}
				{/* </Paper> */}
			</Grid>
			<TransitionsSnackbar />
		</Grid>

	);
}



export default Login;



