import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';

//MUI and Icons Import
import { Box, Grid, Avatar, Typography, Rating, Button, Tabs, Tab, Stack, IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star'
import CloseIcon from '@mui/icons-material/Close'
import profileImage from "../../assets/images/candidate-profile-image.png"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

//Components & data Import
import BasicInformation from './../AdminDashboard/requestIdDrawerComponents/BasicInformation';
import Resume from './../AdminDashboard/requestIdDrawerComponents/Resume';
import InterviewFeedback from './../AdminDashboard/requestIdDrawerComponents/InterviewFeedback';
import Comments from './../AdminDashboard/requestIdDrawerComponents/Comments';
import BasicInformationData from '../../datafiles/AdminDashboard/basicInformationDummyData.json'
import RenderHelper from "../../form/ReactiveForm";
import FormHelper from "../../form/FormHelper";
import { Save } from "@mui/icons-material";
import ApiService from "../../utils/ApiService";
import { setUserInfo } from "../../features/userInfoStore/userSlice";
import { setSnackBar } from "../../features/snakebar/snakebar";
import { LoadingButton } from "@mui/lab";

let tempBI = BasicInformationData

localStorage.setItem("tempBI", JSON.stringify(tempBI))

//Tab Panels Code
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

//Component code starts here
const AddSkillsDrawer = ({ id, parentId, setOpenSkills, drawerHeading, candidateDetailsModel, userSkillsDetails }) => {
    console.debug("close : id ", id)
    const dispatch = useDispatch();
    const userData = useSelector((state) => state?.user?.userListName);
    const skillList = useSelector(state => state?.toolsList?.skills)
    const userSkillsData = useSelector((state) => state?.drawer?.data ?? []);
    // {} by default
    console.debug("userSkillsData", userSkillsDetails)
    const handleDrawerClose = () => {
        // console.debug("close : parentId ", parentId)
        // dispatch(closeDrawer(parentId));
        setOpenSkills(false)
    };
    const [value, setValue] = React.useState(0);
    const [showErrors, setShowErrors] = React.useState(0);
    const [loading, setLoading] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const allowedCharactersRegex = /^[a-zA-Z]+$/;
    // ,{name: 'allowedCharacters',validate: (value) => {return allowedCharactersRegex.test(value);},message: 'Field must only contain alphabets.'},

    const skillDetailsModel = [{
        label: 'Skills', type: "autoComplete", data: skillList, freeSolo: true, value: userSkillsData.length?.map(v => v.skill), sx: { gridColumn: 'span 6', m: 1 },
        validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?!\s)[a-zA-Z0-9\s_\-]{2,}(?!\s)$/, errorMessage: 'Field must only contain letters, _, -, and spaces' }], multiple: true
    }];

    // let skillDetailsModel = [
    //     { label: 'Skills', type: "autoComplete", data: skillList,  multiple: true,freeSolo: true, value: [], sx: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' },{ name: 'pattern', regex: /^(?! )[a-zA-Z, ]+$/, errorMessage: 'Field must only contain alphabets with , specific character' }] },
    // ];
    let skillDetailsHelper = new FormHelper({ model: skillDetailsModel, id: 'candidateDetails' });

    const [candidateDetailsHelperData, setCandidateDetailsHelperData] = useState(skillDetailsModel);
    console.debug("fbrfjnwjenfjnejfnkew", userSkillsDetails)

    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }

    const addContactDetail = () => {
        let dataCandidateDetalsHelper = candidateDetailsHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);
        console.log({ dataCandidateDetalsHelper })
        var error = false;
        var errorMessage = false;

        for (let i = 0; i < candidateDetailsHelperData.length; i++) {
            for (let j = 0; j < candidateDetailsHelperData[i]?.validation?.length; j++) {
                var res = candidateDetailsHelperData[i]?.validation[j](candidateDetailsHelperData[i].value);
                if (res.error) {
                    error = res.error;
                    errorMessage = res.errorMessage;
                    break;
                }
            }
        }
        if (error) {
            setShowErrors(showErrors + 1);
            return;
        }

        let formData = new FormData();

        userSkillsDetails.map((skill, index) => {
            formData.append('skills[' + index + ']', skill.skill);
        });

        dataCandidateDetalsHelper['Skills'].map((skill, index) => {
            formData.append('skills[' + (userSkillsDetails.length + index) + ']', skill);
        });
        // dataCandidateDetalsHelper['Skills'].map((skill, index) => {
        //     formData.append('skills[' + index + ']', skill);
        // })
        setLoading(true);
        ApiService.postAdopter('updateUser', formData, userData?.userId).then((res) => {
            console.log({ res })
            if (res) {
                handleDrawerClose()
                getUserData();
                if (res.status == 200) {
                    dispatch(setSnackBar({ dataLoad: true, message: "Data added successfully", severity: "success" }))
                    setLoading(false)
                }
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            setLoading(false)
        })

    }

    return (<>
        <Stack sx={{ mb: 2 }} >
            <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                <Box>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={5}>
                            <Box display='flex'>
                                <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Add Skills</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="outlined" size="small" sx={{ mx: 1 }} onClick={handleDrawerClose}><Typography variant="body2">CANCEL</Typography></Button>

                            <LoadingButton
                                size="small"
                                color="primary"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<Save />}
                                variant="contained"
                                // disabled={disableSumbit}
                                onClick={addContactDetail}
                            >
                                <span>SAVE</span>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* <Box sx={{ width: 1 }}> */}
            <RenderHelper showErrors={showErrors} getCurrentValues={(data) => { console.log("Hind Ki Chadar", { data }); setCandidateDetailsHelperData(data) }} {...skillDetailsHelper.model} />
            <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
            </Box>
        </Stack>
        {/* </Box> */}
    </>)
}

export default AddSkillsDrawer;