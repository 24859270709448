import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, Grid, Stack, CardHeader, Chip, List, ListItem, ListItemButton, ListItemText, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import FormHelper from '../../form/FormHelper';
import { Add, Construction, Delete, Edit, EmojiObjects, Interests, Work, } from '@mui/icons-material';
import SharedDrawer from '../../features/drawers/Drawer';
import { openDrawer } from '../../features/drawers/drawerSlice';
import AddEmploymentDrawer from '../../components/AdminDrawers/AddEmployementDrawer';
import AddSkillsDrawer from '../../components/AdminDrawers/AddSkillsDrawer';
import AddToolsDrawer from '../../components/AdminDrawers/AddToolsDrawer';
import SharedDialog from '../../features/dialogBox/dialogBox';
import { openDialog, closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EmployementDetail = ({ readOnly }) => {
    const userEmploymentDetails = useSelector((state) => state?.user?.userListName?.userEmployment);
    console.log("y1", userEmploymentDetails)
    const userSkillsDetails = useSelector((state) => state?.user?.userListName?.skills);
    const userToolsDetails = useSelector((state) => state?.user?.userListName?.toolsUser);
    const [curerentItemToDelete, setCurerentItemToDelete] = useState({});
    const [currentEditEmploymentIndex, setCurrentEditEmploymentIndex] = useState();
    const [openEmployment, setOpenEmployment] = useState(false);
    const [openSkills, setOpenSkills] = useState(false);
    const [openTools, setOpenTools] = useState(false);
    const [expandedAccordion, setExpandedAccordion] = useState(null);
    const openToolsTextfield = () => {
        setOpenTools(!openTools)
    }

    const openEmployementTextfield = () => {
        setOpenEmployment(!openEmployment)
    }

    const openSkillsTextfield = () => {
        setOpenSkills(!openSkills)
    }
    const [hovered, setHovered] = useState(false);
    const dispatch = useDispatch();
    const openEmploymentDrawer = () => {
        setCurrentEditEmploymentIndex(-1)
        dispatch(openDrawer('employment'));
    }
    const openEditEmploymentDrawer = (data, index) => {
        dispatch(openDrawer({ id: 'employment', data }));
        setCurrentEditEmploymentIndex(index);
    }
    const openSkillsDrawer = () => {
        // dispatch(openDrawer({ id: 'education', data }));
        dispatch(openDrawer({ id: 'skills', data: userSkillsDetails }));
    }
    const openToolsDrawer = () => {
        // dispatch(openDrawer('tools'));
        dispatch(openDrawer({ id: 'tools', data: userToolsDetails }));
    }

    const openDeleteDialog = (type, id, index) => {
        setCurerentItemToDelete({ type, id, index });
        dispatch(openDialog('deleteDialog'));
    }

    const deleteHandler = () => {
        if (!curerentItemToDelete.type) {
            return;
        }
        switch (curerentItemToDelete.type) {
            case 'userEmploymentDetails': console.log("Look here", { userEmploymentDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
            case 'userSkillsDetails': console.log("Look here", { userSkillsDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
            case 'userToolsDetails': console.log("Look here", { userToolsDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
        }
    }
    useEffect(function () {
        window.scrollTo(0, 0);
    }, [])


    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };

    
console.debug("userToolsDetails",userToolsDetails)
    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Employment & Skills</Typography>
                    </Box>
                </Stack>


                {/* Employment section Code */}
                <Box>
                    <Card sx={openEmployment ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                        <Accordion expanded={expandedAccordion === 'panel1'} onChange={handleAccordionChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <CardHeader
                                    sx={{ pl: 1 }}
                                    avatar={<Work color={expandedAccordion === 'panel1' ? "primary" : ""} />}
                                    subheader="Please fill your Employment details"
                                    title={<Typography variant='body1' fontWeight="var(--font-weight-5)" >Employment</Typography>}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                {userEmploymentDetails?.map((data, index) => (
                                    <Box sx={{ px: 2, py: 1 }}>
                                        <Typography variant="subtitle1" color="text.primary" sx={{ pb: 2 }}>Employment {index + 1}</Typography>
                                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ p: 0, width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                                            <Grid item xs={4} sx={{ mb: 3 }}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Job Title
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {data.department}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sx={{ mb: 3 }}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Job Type
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {data.designation}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sx={{ mb: 3 }}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Company Name
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {data.previousCompanyName}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sx={{ mb: 3 }}>
                                                <Box>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Location
                                                    </Typography>
                                                    <Typography variant="subtitle2" color="text.primary">
                                                        {data.officeAddress}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {/* <Box>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">Job Title</Typography>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.previousCompanyName}</Typography>
                                                </Box>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.previousCompanyName}</Typography>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">{data.fromDate && data.fromDate - data.toDate && data.toDate}</Typography>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">{data.officeAddress}</Typography>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.department}</Typography> */}
                                            {/* </Grid> */}
                                            {/* <Grid item xs={2} sm={4} md={4} >
                                                <Stepper orientation="vertical">
                                                    <Step>
                                                        <StepLabel startIcon={<Circle />}>edfeef</StepLabel>

                                                    </Step>
                                                    <Step>
                                                        <StepLabel>fefe</StepLabel>
                                                    </Step>

                                                </Stepper>
                                            </Grid> */}
                                        </Grid>
                                        <Divider />
                                    </Box>
                                ))}
                                {openEmployment ?
                                    <AddEmploymentDrawer parentId='employment' currentEditEmploymentIndex={currentEditEmploymentIndex} setOpenEmployment={setOpenEmployment} />
                                    :
                                    <Box textAlign="center" mt={2}>
                                        <Button startIcon={<Add />} onClick={openEmployementTextfield} variant='outlined'>ADD EMPLOYMENT</Button>
                                    </Box>
                                }

                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Box>



                {/* Skills Section Code  */}
                <Card sx={openSkills ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                    <Accordion expanded={expandedAccordion === 'panel2'} onChange={handleAccordionChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <CardHeader
                                sx={{ pl: 1 }}
                                avatar={<EmojiObjects color={expandedAccordion === 'panel2' ? "primary" : ""} />}
                                subheader="Manage and update  your skills"
                                title={<Typography variant='h6'>Skills</Typography>}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            {userSkillsDetails?.map((data) => <Chip label={data.skill} sx={{ ml: 2, mb: 3 }} />)}
                            {openSkills ?
                                <AddSkillsDrawer parentId='skills' openSkillsTextfield={openSkillsTextfield} setOpenSkills={setOpenSkills}  userSkillsDetails={userSkillsDetails}/>
                                :
                                <Box textAlign={"center"}>
                                    <Button startIcon={<Add />} onClick={openSkillsTextfield} variant='outlined'>ADD SKILLS</Button>
                                </Box>
                            }
                        </AccordionDetails>
                    </Accordion>
                </Card>

                {/* Tools Section Code */}
                <Card sx={openTools ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                    <Accordion expanded={expandedAccordion === 'panel3'} onChange={handleAccordionChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <CardHeader
                                sx={{ pl: 1 }}
                                avatar={<Construction color={expandedAccordion === 'panel3' ? "primary" : ""} />}
                                subheader="Manage and update your tools"
                                title={<Typography variant='h6'>Tools</Typography>}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            {userToolsDetails?.map((data) => <Chip label={data.tool} sx={{ m: 2 }} />)}
                            {openTools ?
                                <AddToolsDrawer parentId='tools' openToolsTextfield={openToolsTextfield} setOpenTools={setOpenTools} userToolsDetails={userToolsDetails} />
                                :
                                <Box textAlign={"center"}>
                                    <Button startIcon={<Add />} onClick={openToolsTextfield} variant='outlined'> ADD TOOLS</Button>
                                </Box>
                            }
                        </AccordionDetails>
                    </Accordion>
                </Card>


                {/* Delete Dialog Code  */}
                <SharedDialog id="deleteDialog">
                    <Box pb="1rem">
                        <DialogTitle id="alert-dialog-title">
                            {"Delete"}
                        </DialogTitle>
                        <DialogContent sx={{ padding: '16px 24px' }}>
                            <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                                This will be deleted forever and you won't be able to restore it.
                                {/* {methodsAndState.dynamicDeleteMessage} */}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: '16px 24px' }}>
                            <Button onClick={() => dispatch(closeDialog('deleteDialog'))}>
                                CANCEL
                            </Button>
                            <Button onClick={deleteHandler} color='primary' variant="contained" >YES, DELETE</Button>
                        </DialogActions>
                    </Box>
                </SharedDialog>
            </Box>
        </Box >
    )
}

export default EmployementDetail