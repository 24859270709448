import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, Stack, styled, CardHeader, Tooltip, Skeleton, } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector, } from 'react-redux';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';
import ApiService from '../../utils/ApiService';
import { setSnackBar } from '../../features/snakebar/snakebar';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Apartment, Call } from '@mui/icons-material';


const OfficialDetail = ({ readOnly }) => {
  const dispatch = useDispatch();
  const [referralData, setReferralData] = useState([])
  const [loadingReferral, setLoadingReferral] = useState(false)
  const [accordionOpen, setAccordionOpen] = useState(false);

  const userOfficialData = useSelector((state) => state?.user?.userListName);
  const MUICard = styled(Card)(({ theme }) => ({
    padding: 0,
    border: '1px solid #E3E3E3',
    ':hover': {
      background: '#DBEAFE'
    },
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)'
  }));
  let inputProps = readOnly ? { readOnly: readOnly } : {};

  let officialDetailsModel = [
    { inputProps: inputProps, label: 'Employee Id', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[A-Za-z0-9_-]+$/, errorMessage: 'Field must only contain alphabets and _ ,- ' }] },
    { inputProps: inputProps, label: 'Date Of Joining', type: "dateField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' }] },
    { inputProps: inputProps, label: 'Job Title', type: "textField", value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }] },
    { inputProps: inputProps, label: 'Supervisor', type: "textField", value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }] },
    { inputProps: inputProps, label: 'Department', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[A-Za-z0-9_-]+$/, errorMessage: 'Field must only contain alphabets and some specific character' }], variant: "outlined" },
    { inputProps: inputProps, label: 'Official Email', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required', regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z._%+-]+\.[a-zA-Z]$/i, errorMessage: 'invalid email' }] },
    // { inputProps: inputProps, label: 'Refer By', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }] },
    // { inputProps: inputProps, label: 'Hiring Manager', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 2 ', m: 1 }, validators: [{ name: 'required' }] },
  ];
  const [officialDetailsHelperData, setOfficialDetailsHelperData] = useState(officialDetailsModel);
  const officialDetailsHelper = new FormHelper({ model: officialDetailsModel, id: 'personalDetails' });
  const setValues = () => {
    // if (userData.userName) {
    officialDetailsHelper.set({
      'Employee Id': userOfficialData?.["employeeId"],
      'Date Of Joining': userOfficialData?.["joiningDate"],
      'Job Title': userOfficialData?.designation?.['designation'],
      'Supervisor': userOfficialData?.supervisorName?.['name'],
      'Department': userOfficialData?.department?.["department"],
      'Official Email': userOfficialData?.['email'],
      'Refer By': userOfficialData?.reference?.['referenceName'],
      'Hiring Manager': userOfficialData?.['contactNo'],
    }, () => {
      console.log("Jiren Done")
    })
  }
  useEffect(function () {
    setValues();
  }, [userOfficialData])

  useEffect(function () {
    setValues();
    let params = {
      requestUserId: userOfficialData.userId,
      applicantRelated: ["job", "applicantReferralStatus", "status"]
    }
    setLoadingReferral(true)
    window.scrollTo(0, 0);
    ApiService.get('myReferral', params).then((res) => {
      let data = res.data.data;
      if (data) {
        setReferralData(data)
        console.debug("Referraldata", data)
        setLoadingReferral(false)
        // setCountries(data.map(v => {
        //     return {
        //         // value: v.countryId,
        //         value: v.country,
        //         label: v.country
        //     }
        // }))
      }
    }).catch((error) => {
      console.log(error);
      dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
      setLoadingReferral(false);
    })
  }, [])
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setAccordionOpen(isExpanded);
};
  console.debug("userOfficialData", userOfficialData)
  return (
    <Box>
      <Box sx={{ minHeight: '55rem' }}>
        <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ py: '1rem' }}>
            <Typography variant="h4" fontWeight="var(--font-weight-5)">Official Details</Typography>
          </Box>
        </Stack>
        {/* </Box> */}
        <Card sx={{ mb: 3, pb: 0 }}>
          <Accordion expanded={accordionOpen} onChange={handleAccordionChange("panel1")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <CardHeader
                sx={{ pl: 1 }}
                avatar={<Apartment color={accordionOpen?'primary':""}/>}
                subheader="Find information about your official details"
                title={<Typography variant='body1' fontWeight="var(--font-weight-5)" >My Office Details</Typography>}
              />
            </AccordionSummary>
            <AccordionDetails>
              <CardContent>
                <Box sx={{ flexGrow: 1, }}>
                  <Box sx={{ my: '1rem' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4}>
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary">
                            Employee Id
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" color="text.primary">
                            {userOfficialData?.employeeId ?? '--'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary">
                            Date Of Joining
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" color="text.primary">
                            {userOfficialData?.joiningDate ?? '--'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary">
                            Job Title
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" color="text.primary">
                            {userOfficialData?.designation?.designation ?? '--'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary">
                            Supervisor
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" color="text.primary">
                            {userOfficialData?.supervisorName?.name ?? '--'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary">
                            Department
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" color="text.primary">
                            {userOfficialData?.department?.department ?? '--'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary">
                            Official Email
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" color="text.primary" sx={{ wordBreak: "break-word" }}>
                            {userOfficialData?.email ?? '--'}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box sx={{ flexGrow: 1, pt: 1 }}>
                  {/* <Grid container spacing={3}>
  {!loadingReferral ? (
    referralData?.length > 0 ? (
      referralData.map((data) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={data.id}>
          <MUICard>
            <CardHeader
              title={
                <Box>
                  <Tooltip
                    title={`${data.firstName ? data.firstName : " "} ${
                      data.lastName ? data.lastName : " "
                    }`}
                  >
                    <Typography
                      variant="body1"
                      className="overflow-text-Announcement"
                      fontWeight={500}
                    >
                      {`${data.firstName ? data.firstName : " "} ${
                        data.lastName ? data.lastName : " "
                      }`}
                    </Typography>
                  </Tooltip>
                </Box>
              }
              subheader={
                <Typography
                  variant="subtitle2"
                  className="overflow-text-Announcement"
                  color="secondary.textSecondary"
                >
                  {data.job ? data.job.jobTitle : "Software Engineer"}
                </Typography>
              }
            />
            <CardContent sx={{ pt: "0px" }}>
              <Grid container spacing={1}>
                <Grid item xs={4.5} sm={4} style={{ color: "#475569" }}>
                  <Typography variant="body2">Department: </Typography>
                </Grid>
                <Grid item xs={7.5} sm={8}>
                  <Typography variant="body2">
                    {data?.job?.department?.departmentName}
                  </Typography>
                </Grid>
                <Grid item xs={4.5} sm={4} style={{ color: "#475569" }}>
                  <Typography variant="body2">Years of Exp:</Typography>
                </Grid>
                <Grid item xs={7.5} sm={8}>
                  <Typography variant="body2">
                    {data.experience ? data.experience : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </MUICard>
        </Grid>
      ))
    ) : (
      <Box>
        <Typography></Typography>
      </Box>
    )
  ) : (
    [1, 2, 3].map((item) => (
      <Grid item xs={12} sm={6} md={4} lg={4} key={item}>
        <MUICard>
          <CardHeader
            title={
              <Box>
                <Typography
                  variant="body1"
                  className="overflow-text-Announcement"
                  fontWeight={500}
                >
                  <Skeleton width="100%" />
                </Typography>
              </Box>
            }
            subheader={
              <Typography
                variant="subtitle2"
                className="overflow-text-Announcement"
                color="secondary.textSecondary"
              >
                <Skeleton width="50%" />
              </Typography>
            }
          />
          <CardContent sx={{ pt: "0px" }}>
            <Grid container spacing={1}>
              <Grid item xs={4.5} sm={4} style={{ color: "#475569" }}>
                <Typography variant="body2">
                  <Skeleton width="100%" />
                </Typography>
              </Grid>
              <Grid item xs={7.5} sm={8}>
                <Typography variant="body2">
                  <Skeleton width="100%" />
                </Typography>
              </Grid>
              <Grid item xs={4.5} sm={4} style={{ color: "#475569" }}>
                <Typography variant="body2">
                  <Skeleton width="100%" />
                </Typography>
              </Grid>
              <Grid item xs={7.5} sm={8}>
                <Typography variant="body2">
                  <Skeleton width="100%" />
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </MUICard>
      </Grid>
    ))
  )}
</Grid>  */}
                </Box>
              </CardContent>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Box>
    </Box>
  )
}

export default OfficialDetail