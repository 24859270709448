import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Chip, Grid, Divider, Skeleton, Stack, Dialog, DialogContent, CardHeader, IconButton, FormControlLabel, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { AccountCircle, Add, Delete, Edit, MoreVert } from '@mui/icons-material';
import ApiService from '../../utils/ApiService';
import { current } from '@reduxjs/toolkit';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const DeviceSigin = ({ readOnly }) => {
    const [deviceList, setDeviceList] = useState([]);
    const [hovered, setHovered] = useState(false);

    useEffect(function () {
        ApiService.get('deviceSignIn').then(res => {
            let response = res.data
            if (response) {
                setDeviceList(response)
            }
        }).catch((error) => {
            console.log(error);
            // dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }, [])
    const [openIPAddress, setOpenIPAddress] = useState(false);

    const ipAddressDetails = [
        { id: 1, deviceName: "hola 1", deviceAddress: "prayagraj", ipAddress: "192.168.0.1", current: 0 },
        { id: 2, deviceName: "hola 2", deviceAddress: "prayagraj", ipAddress: "192.168.0.2", current: 1 },
        { id: 3, deviceName: "hola 3", deviceAddress: "prayagraj", ipAddress: "192.168.0.3", current: 0 },
        // Add more sample data as needed
    ];
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const handleDeleteConfirmationOpen = (index) => {
        setDeleteIndex(index);
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteConfirmationClose = () => {
        setDeleteIndex(null);
        setDeleteConfirmationOpen(false);
    };

    const handleDelete = () => {
        // Implement your delete logic here using deleteIndex
        // For example: dispatchDeleteAction(deleteIndex);
        handleDeleteConfirmationClose();
    };
    const [loading, setLoading] = useState(true);
    // const [ipAddressDetails, setIpAddressDetails] = useState([]);

    // useEffect(() => {
    //     ApiService.get('device').then((res) => {
    //         console.log({ res })
    //         if (res) {
    //             if (res.status == 200) {
    //                 setIpAddressDetails(res.data.data)
    //             console.debug("device api response", res.data.data)
    //             }
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //         // setLoading(false)
    //     })
    // }, []); // Empty dependency array ensures the effect runs only once

    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Device Sign-ins</Typography>
                    </Box>
                </Stack>
                <Card>
                    <Box>
                        {ipAddressDetails ? (
                            [ipAddressDetails.find(data => data?.current === 1), ...ipAddressDetails.filter(data => data?.current !== 1)].map((data, index) => (
                                <Box sx={{ p: 2, py: 1 }} key={index}>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="subtitle1" color="text.primary" sx={{ pb: 2 }}>
                                            Device {index + 1}
                                        </Typography>
                                        {data?.current === 1 ? (
                                            <Chip label="Current Session" color="primary" />
                                        ) : (
                                            <IconButton aria-label="delete" onClick={() => handleDeleteConfirmationOpen(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={4} sx={{ mb: 3 }}>
                                            <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Device Name</Typography>
                                            <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data?.deviceName}</Typography>
                                        </Grid>
                                        <Grid item xs={4} sx={{ mb: 3 }}>
                                            <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Device Address</Typography>
                                            <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.deviceAddress}</Typography>
                                        </Grid>
                                        <Grid item xs={4} sx={{ mb: 3 }}>
                                            <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >IP Address</Typography>
                                            <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.ipAddress}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </Box>
                            ))
                        ) : (
                            [1, 2].map(d => <ListItem key={d}><Skeleton variant="text" sx={{ width: "100%", height: 40 }} /></ListItem>)
                        )}
                        <Dialog
                            open={deleteConfirmationOpen}
                            onClose={handleDeleteConfirmationClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Delete Device?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    This will be deleted forever and you won't able to restore it?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDeleteConfirmationClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleDelete} variant="contained" >
                                    Yes, Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Card>

                {/* <Box>
                    <Card sx={{ mb: 3, p: 3, pb: 0 }}>
                        <CardContent sx={{ p: 0 }}>
                            <List >
                                {deviceList.length>0 &&deviceList.map((data) =>
                                    <ListItem onMouseEnter={() => setHovered(true)}
                                        onMouseLeave={() => setHovered(false)} sx={{ p: 0, mb: 3 }} >
                                        <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                            <ListItemText>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data?.name}</Typography>
                                                <Typography variant='body2' fontWeight="var(--font-weight-4)">{data?.access_token_id}</Typography>
                                            </ListItemText>
                                            <Box alignSelf={"center"}>
                                               {data.current_token.id=== data.access_token_id &&<Typography variant='caption' color="primary">Current Session</Typography>}
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>)
                                }
                                <ListItem onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)} sx={{ p: 0, mb: 3 }} >
                                    <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                        <ListItemText>
                                            <Typography variant='body1' fontWeight="var(--font-weight-5)">Mobile</Typography>
                                            <Typography variant='body2' fontWeight="var(--font-weight-4)">Ghaziabad, Uttar Pradesh, India</Typography>
                                        </ListItemText>
                                        {hovered && (
                                            <Box alignSelf={"center"}>
                                                <Button ><Delete /></Button>
                                            </Box>)}
                                    </ListItemButton>
                                </ListItem>
                            </List>

                        </CardContent>
                    </Card>
                </Box> */}
            </Box>
        </Box>
    )
}

export default DeviceSigin