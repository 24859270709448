import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Header from '../header/Header'
import Footer from '../../layouts/footer/Footer';
import { Link } from 'react-router-dom';
import { Collapse, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentRoute } from '../../features/sidebarRouting/sidebarSelectionSlice';
import './Sidebar.css';
import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { associateSpotlightUrl, bankUrl, contactDetailUrl, deviceSignInUrl, documentUrl, educationUrl, employementUrl, familyDetailUrl, ipAddressUrl, officialDetailUrl, passwordUrl, personalDetailUrl, } from '../../_constant/urlConstData';
import SecurityIcon from '@mui/icons-material/Security';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// const drawerWidth = 375;

// const openedMixin = (theme) => ({
//     width: drawerWidth,
//     height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(9)})`,
//     height: '100%',
//     transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.enteringScreen,
//     }),
//     overflowX: 'hidden',
//     borderRight: 0,
//     // position: 'relative',
//     top: 56,
//     paddingRight: 15,
// });

// const closedMixin = (theme) => ({

//     height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(9)})`,
//     transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     overflowX: 'hidden',
//     borderRight: 0,
//     background: '#ffffffbf',
//     top: 63,
//     width: `calc(${theme.spacing(7)} + 1px)`,
//     [theme.breakpoints.up('xs')]: {
//         width: `calc(${theme.spacing(0)} + 1px)`,
//     },
//     [theme.breakpoints.up('sm')]: {
//         width: `calc(${theme.spacing(8)} + 1px)`,
//     },
//     [theme.breakpoints.up('md')]: {
//         width: `calc(${theme.spacing(8)} + 1px)`,
//     },
//     [theme.breakpoints.up('lg')]: {
//         width: `calc(${theme.spacing(8)} + 1px)`,
//     },
// });

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    height: `calc(100vh - 0px - ${theme.spacing(9)})`,
    height: '100%',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    borderRight: 0,
    // position: 'relative',
    top: 61,
    paddingRight: 15,
});

const closedMixin = (theme) => ({
    // height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(9)})`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    borderRight: 0,
    background: '#ffffff',
    top: 63,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('xs')]: {
        width: `calc(${theme.spacing(0)} + 1px)`,
    },
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.up('md')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.up('lg')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => (prop !== 'open' || prop !== 'mobileOpen'),
})(({ theme, open, mobileOpen }) => ({
    // zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...((open || mobileOpen) && {
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => (prop !== 'open' || prop !== 'mobileOpen') })(
    ({ theme, open, mobileOpen }) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        zIndex: 2,
        boxSizing: 'border-box',
        ...((open || !mobileOpen) && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...((!open || mobileOpen) && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


// const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: (prop) => (prop !== 'open' || prop !== 'mobileOpen'),
// })(({ theme, open, mobileOpen }) => ({
//     // zIndex: theme.zIndex.drawer - 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...((open || mobileOpen) && {
//         // width: `calc(100% - ${drawerWidth}px)`,
//         transition: theme.transitions.create(['width', 'margin'], {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//     }),
// }));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => (prop !== 'open' || prop !== 'mobileOpen') })(
//     ({ theme, open, mobileOpen }) => ({
//         flexShrink: 0,
//         whiteSpace: 'nowrap',
//         zIndex: 2,
//         boxSizing: 'border-box',
//         ...((open || !mobileOpen) && {
//             ...openedMixin(theme),
//             '& .MuiDrawer-paper': openedMixin(theme),
//         }),
//         ...((!open || mobileOpen) && {
//             ...closedMixin(theme),
//             '& .MuiDrawer-paper': closedMixin(theme),
//         }),
//     }),
// );

export default function Sidebar({ children, ...props }) {

    const [open, setOpen] = React.useState(true);
    const { currentRoute } = useSelector(state => state.routing)
    const { window } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = React.useState(isMobile);
    const [selectedId, setSelectedId] = React.useState(([passwordUrl.list, ipAddressUrl.list, deviceSignInUrl.list].indexOf(window.location.pathname) != -1) ? 2 : 1);
    const [openMenu, setOpenMenu] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState([null, null]);
    // const [openMenuBar, setOpenMenuBar] = React.useState(-1);
    // const openMenuBar = Boolean(anchorEl);

    const handleClickListItem = (event, index) => {
        console.log({ event })
        let tempAnchorEl = [...anchorEl];
        tempAnchorEl[index] = event.currentTarget;
        setAnchorEl(tempAnchorEl);
    };

    const handleMenuItemClick = (event, index) => {
        // setSelectedIndex(index);
        let tempAnchorEl = [...anchorEl];
        tempAnchorEl[index] = null;
        setAnchorEl(tempAnchorEl);
    };

    const handleClose = () => {
        let tempAnchorEl = anchorEl.map(v => null);
        setAnchorEl(tempAnchorEl);
    };

    const dispatch = useDispatch();

    const handleDrawerOpen = () => {
        if (open === false) {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
        console.log({ open })
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const itemsList = [
        {
            id: "1", name: "Profile", icon: <RecentActorsIcon />, subList: [
                { id: "1", label: "Personal Details", path: personalDetailUrl.list },
                { id: "1", label: "Contact  Details", path: contactDetailUrl.list },
                { id: "1", label: "Official Details", path: officialDetailUrl.list },
                { id: "1", label: "Family Details", path: familyDetailUrl.list },
                { id: "1", label: "Education & Certifications", path: educationUrl.list },
                { id: "1", label: "Employment Details", path: employementUrl.list },
                { id: "1", label: "Bank  Details", path: bankUrl.list },
                // { id: "1", label: "Documents",  path: documentUrl.list },
            ]
        },
        {
            id: "2", name: "Security", icon: <SecurityIcon />, subList: [
                { id: "2", label: "Password", path: passwordUrl.list },
                // { id: "2", label: "Allowed IP Address", path: ipAddressUrl.list },
                // { id: "2", label: "Device Sign-ins", path: deviceSignInUrl.list },


            ]
        },
    ];

    const handleListItemClick = (id) => {
        setOpenMenu(true); // Always open the clicked collapse
        setSelectedId(id);
    };

    const handleClick = (id, path) => {
        dispatch(setCurrentRoute(path));
        setSelectedId(id)
        setOpenMenu(!openMenu);
    };
    
    const handleClickSubMenu = (id, path) => {
        console.log({ id, path })
        dispatch(setCurrentRoute(path));
        setSelectedId(id)
        let tempAnchorEl = anchorEl.map(v => null);
        setAnchorEl(tempAnchorEl);
        // setOpenMenu(!openMenu);
    };

    const renderSubList = (item, subList) => (
        <Collapse in={item.id == selectedId && openMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {console.log({ subList, currentRoute })}
                {subList.map((subItem, index) => (<React.Fragment key={subItem.id}>
                    <ListItem disablePadding sx={{ display: 'block' }}
                        component={Link}
                        to={subItem.path}
                        onClick={() => handleClickSubMenu(subItem.id, subItem.path)}
                        //   onClick={(e) => handleListItemClick(e, item.path)}
                        className={(mobileOpen || open) ? "open-selected-tab" : "close-selected-tab"}
                        style={
                            currentRoute === subItem.path
                                ? {
                                    // background: '#DBEAFE',
                                    color: "#1D76FE"
                                    // boxShadow: "2px 3px 6px rgba(0, 0, 0, 0.3)",
                                }
                                : {
                                    color: "black"
                                }
                        }
                    >
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText  style={{ display: 'flex',marginLeft:'2.5rem'}} primary={<Typography variant="subtitle2">{subItem.label}</Typography>} />
                        </ListItemButton>
                    </ListItem>
                </React.Fragment>))}
            </List>
        </Collapse>
    );

    return (
        <Box>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="fixed" open={mobileOpen} sx={{ background: "#EEF6FF", boxShadow: 'none' }}>
                    <Toolbar>
                        <IconButton
                            color="black"
                            aria-label="open drawer"
                            onClick={(window.innerWidth > 600) ? handleDrawerOpen : handleDrawerToggle}
                            edge="start"
                            sx={{
                                // mb: isTrash ? '2rem !important' : '',

                                marginRight: 0,
                                ...(open),
                                color: '#000000',
                                display: { xs: 'block', sm: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                            }}
                        >
                            {(!mobileOpen) ? <MenuOpenIcon /> :
                                <Close sx={{ color: 'var(--color-black)' }} />}
                        </IconButton>
                        <Header />
                    </Toolbar>
                </AppBar>

                <Drawer
                    className="sidebar-drawer"
                    variant={(window.innerWidth > 600) ? "permanent" : "temporary"}
                    open={(window.innerWidth > 600) ? open : mobileOpen}
                    onClose={(window.innerWidth > 600) ? handleDrawerOpen : handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        top: 0,
                        backgroundColor: "#ffffffbf",
                        '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                    }}
                >
                    <List sx={{ textAlign: '-webkit-center' }}>
                        {itemsList.map((item, index) => (
                            <React.Fragment key={'' + item.id + index}>
                                {open && item.subList ? (
                                    <ListItem key={item.id} disablePadding sx={{ display: 'block' }}
                                        component={Link}
                                        to={item.path}
                                        onClick={() => handleListItemClick(item.id)}
                                        className={(mobileOpen || open) ? "open-selected-tab" : "close-selected-tab"}
                                        style={selectedId == (index + 1) ? { color: "black", background: '#DBEAFE' } : { color: "black" }}
                                    >
                                        <ListItemButton>
                                            <ListItemIcon style={selectedId == (index + 1) ? { color: "#2563EB", } : { color: "black" }}>{item.icon}</ListItemIcon>
                                            <ListItemText primary={<Typography variant="subtitle1">{item.name}</Typography>} />
                                            {item.id == selectedId && openMenu ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </ListItem>
                                ) : !open && item.subList ? (
                                    <ListItem disablePadding sx={{ display: 'block' }}
                                        component={Link}
                                        to={item.path}
                                        onMouseEnter={(event) => handleClickListItem(event, index)}
                                        //   onClick={(e) => handleListItemClick(e, item.path)}
                                        className={(mobileOpen || open) ? "open-selected-tab" : "close-selected-tab"}
                                        style={selectedId == (index + 1) ? { color: "black", background: '#DBEAFE' } : { color: "black" }}
                                    >
                                        {console.debug("currentRoute", currentRoute)}
                                        {console.log("Second", { mobileOpen, open })}
                                        <ListItemButton>
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            {/* <NavigateNextIcon /> */}
                                            {/* <ListItemText primary={item.name} /> */}
                                        </ListItemButton>
                                    </ListItem>
                                ) : (
                                    <ListItem disablePadding sx={{ display: 'block' }}
                                        component={Link}
                                        to={item.path}
                                        onClick={() => handleClick(item.id, item.path)}
                                        //   onClick={(e) => handleListItemClick(e, item.path)}
                                        className={(mobileOpen || open) ? "open-selected-tab" : "close-selected-tab"}
                                        style={selectedId == (index + 1) ? { color: "black", background: '#DBEAFE' } : { color: "black" }}
                                    >
                                        <ListItemButton>
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText primary={<Typography variant="subtitle1">{item.name}</Typography>} />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                                {open && item.subList && renderSubList(item, item.subList)}
                                {!open && item.subList && (<Menu
                                    anchorEl={anchorEl[index]}
                                    open={anchorEl[index]}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'lock-button',
                                        role: 'listbox',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    {item.subList.map((option, i) => (
                                        <MenuItem
                                            key={'' + option.id + index}
                                            component={Link}
                                            to={option.path}
                                            selected={currentRoute === option.path}
                                            onClick={() => handleClickSubMenu(option.id, option.path)}
                                        >
                                            <ListItemText
                                                primary={<Typography variant="subtitle2">{option.label}</Typography>}
                                            />
                                        </MenuItem>
                                    ))}
                                </Menu>)}
                            </React.Fragment>

                        ))}
                    </List>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, pt: 8, pl: 2, pr: 2, background: '#EEF6FF', minHeight: '92vh', minWidth: 0 }}>
                    {children}
                </Box>
            </Box>
            <Grid item xs={12}>
                <AppBar position="static" sx={{ background: "#FFFFFF" }}>
                    <Footer />
                </AppBar>
            </Grid>
        </Box>
    );
}