import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Stack, List, CardHeader, ListItem, ListItemButton, ListItemText, Grid, Divider, Skeleton } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import { Add, Delete, Edit, FamilyRestroom, } from '@mui/icons-material';
import SharedDrawer from '../../features/drawers/Drawer';
import { openDrawer } from '../../features/drawers/drawerSlice';
import AddFamilyDrawer from '../../components/AdminDrawers/AddFamilyDrawer';
import SharedDialog from '../../features/dialogBox/dialogBox';
import { closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FamilyDetail = ({ readOnly }) => {
    const dispatch = useDispatch();
    const userFamilyData = useSelector((state) => state?.user?.userListName?.userFamilyInfo);
    const [hoveredItems, setHoveredItems] = useState(Array(userFamilyData?.length).fill(false));
    const [curerentItemToDelete, setCurerentItemToDelete] = useState({});
    const [currentEditFamilyIndex, setCurrentEditFamilyIndex] = useState();
    const [openEmail, setOpenEmail] = useState(false);
    const [openFamily, setOpenFamily] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState(false);


    const openFamilyTextfield = () => {
        setOpenFamily(!openFamily)
    }


    const deleteHandler = () => {
        if (!curerentItemToDelete.type) {
            return;
        }
        switch (curerentItemToDelete.type) {
            case 'userFamilyData': console.log("Look here", { userFamilyData, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
        }
    }

    useEffect(function () {
        window.scrollTo(0, 0);
    }, [])
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setAccordionOpen(isExpanded);
    };
    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Family Details</Typography>
                    </Box>
                </Stack>
                <Card sx={openFamily ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                    <Accordion expanded={accordionOpen} onChange={handleAccordionChange("panel1")}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <CardHeader
                                sx={{ pl: 1 }}
                                avatar={<FamilyRestroom color={accordionOpen?'primary':""}/>}
                                subheader="Please fill your family details"
                                title={<Typography variant='body1' fontWeight="var(--font-weight-5)" >My Family Details</Typography>}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            {userFamilyData ? userFamilyData?.map((data, index) => (

                                <Box sx={{ px: 2, py: 1 }}>
                                    <Typography variant="subtitle1" color="text.primary" sx={{ pb: 2 }}>Member {index + 1}</Typography>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={6} md={4} sx={{ mb: 3 }}>
                                            <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Member Name</Typography>
                                            <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.name?data.name:"-"}</Typography>
                                        </Grid>
                                        <Grid item xs={6}  md={4} sx={{ mb: 3 }}>
                                            <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Relation</Typography>
                                            <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.type?data.type:"-"}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </Box>
                            )) : [1, 2].map(d => (
                                <ListItem key={d}>
                                    <Skeleton variant="text" sx={{ width: "100%", height: 40 }} />
                                </ListItem>
                            ))}
                            {openFamily?
                                < AddFamilyDrawer parentId='family' currentEditFamilyIndex={currentEditFamilyIndex} setOpenFamily={setOpenFamily} />
                            :
                            <Box textAlign={"center"}>
                                <Button startIcon={<Add />} variant="outlined" onClick={openFamilyTextfield}>ADD FAMILY DETAILS</Button>
                            </Box>
                         }

                        </AccordionDetails>
                    </Accordion>
                </Card>
                <SharedDrawer id="family">
                    <AddFamilyDrawer parentId='family' currentEditFamilyIndex={currentEditFamilyIndex} />
                </SharedDrawer>
                <SharedDialog id="deleteDialog">
                    <Box pb="1rem">
                        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
                        <DialogContent sx={{ padding: '16px 24px' }}>
                            <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                                This will be deleted forever and you won't be able to restore it.
                                {/* {methodsAndState.dynamicDeleteMessage} */}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: '16px 24px' }}>
                            <Button onClick={() => dispatch(closeDialog('deleteDialog'))}>CANCEL</Button>
                            <Button onClick={deleteHandler} color='primary' variant="contained">YES, DELETE</Button>
                        </DialogActions>
                    </Box>
                </SharedDialog>
            </Box>
        </Box>
    );

}
export default FamilyDetail