import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Grid, Typography, Link, Card, Alert } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import FolderIcon from '@mui/icons-material/Folder';
// import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { openDialog, closeDialog } from "../../features/dialogBox/dialogBoxSlice";
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ApiService from "../../utils/ApiService";
import { setSnackBar } from "../../features/snakebar/snakebar";
import { setUserInfo } from "../../features/userInfoStore/userSlice";
import ProfileImage from "../../assets/images/profileImage.jpg"


const ChangeImageProfile = ({ parentId, id }) => {
    const dispatch = useDispatch();
    const [imagePreviews, setImagePreviews] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [profile, setProfile] = useState();
    const [imageThumb, setImageThumb] = useState();

    const userData = useSelector((state) => state?.user?.userListName);

    console.log("z1", imagePreviews)

    const handleDialogClose = () => {
        dispatch(closeDialog(parentId));
    };
    const [alertMessage, setAlertMessage] = useState('');
    // const handleFileChange = (e) => {
    //     // const fileThumbnail = e.target.files[0];
    //     const file = e.target.files[0];
    //     if (file) {
    //         const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    //         if (!allowedTypes.includes(file.type)) {
    //             setAlertMessage('Please select a valid file.');
    //             return;
    //         }
    //         const url = URL.createObjectURL(file);
    //         setImageThumb(file);
    //         setSelectedFile(url);
    //         setProfile(file)
    //     }
    //     console.log(selectedFile)
    // };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
            if (!allowedTypes.includes(file.type)) {
                setAlertMessage('Please select a PNG, JPEG, JPG, GIF file only.');
                setSelectedFile(null); // Clear selectedFile when an invalid file is selected
                setProfile(null); // Clear profile when an invalid file is selected
                return;
            }
            const url = URL.createObjectURL(file);
            setImageThumb(file);
            setSelectedFile(url);
            setProfile(file);
            setAlertMessage(''); // Clear alertMessage when a valid file is selected
        }
    };


    const handleSave = () => {
        let formData = new FormData();
        formData.append('avatar', profile);

        ApiService.postAdopter('updateUser', formData, userData.userId).then((res) => {
            console.log({ res })
            if (res) {
                handleDialogClose()
                getUserData()
                if (res.status == 200) {
                    dispatch(setSnackBar({ dataLoad: true, message: "Data added successfully", severity: "success" }))
                    // setLoading(false)
                }
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            // setLoading(false)
        })
    }

    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
            if (!allowedTypes.includes(file.type)) {
                setAlertMessage('Please select a PNG, JPEG, JPG, GIF file only.');
                setSelectedFile(null);
                setProfile(null);
                return;
            }
            const url = URL.createObjectURL(file);
            setImageThumb(file);
            setSelectedFile(url);
            setProfile(file);
            setAlertMessage(''); // Clear alertMessage when a valid file is selected
        }
    };


    return (
        <>
            <Box sx={{ width: '30rem', }}>
                <DialogTitle id="dialog-title" sx={{ backgroundColor: 'var(--color-lightblue)', padding: 'var(--padding-none)' }}>
                    <Stack sx={{ backgroundColor: "var(--color-lightblue)" }}>
                        <Box sx={{ p: '0.25rem 1rem' }}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item xs={10}>
                                    <Box pl='1rem' display='flex' sx={{ flexDirection: 'column' }}>
                                        <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Change Profile Picture </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton aria-label="upload picture" component="label" onClick={handleDialogClose} color='var(--color-black)'>
                                        <CloseIcon sx={{ color: 'var(--color-black)' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </DialogTitle>
                <Divider />
                {alertMessage && (
                    <Alert variant="none" sx={{ color: "red" }} severity="error">
                        {alertMessage}
                    </Alert>
                )}
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            {selectedFile || userData.userAvatar ? (
                                <img
                                    src={selectedFile ? selectedFile : userData.userAvatar ? userData.userAvatar : ProfileImage}
                                    alt="Selected"
                                    height={100}
                                    width={100}
                                    style={{
                                        borderRadius: '50%',
                                        height: '100px',
                                        width: '100px',
                                    }}
                                />
                            ) : (
                                <img
                                    src={ProfileImage}
                                    alt="Fallback"
                                    height={100}
                                    width={100}
                                    style={{
                                        borderRadius: '50%',
                                        height: '100px',
                                        width: '100px',
                                    }}
                                />
                            )}
                        </Grid>


                        <Grid item xs={8}>
                            <Card sx={{
                                border: '1px dashed', height: '100%',
                                cursor: 'pointer',
                                backgroundColor: "rgba(201, 201, 201, 0.1)",
                                alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', padding: '1.5rem'
                            }}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                            >
                                <label htmlFor="fileInput" style={{ cursor: 'pointer', alignItems: 'center', }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Avatar sx={{ height: '2.5rem', width: '2.5rem', marginRight: '8px', backgroundColor: '#1976D21F' }}>
                                            <UploadFileIcon color="primary" />
                                        </Avatar>
                                    </Box>
                                    <Box sx={{ display: 'flex', mt: '0.5rem' }}>
                                        <span>
                                            <Link><Typography variant="body1">Click to upload</Typography></Link>
                                        </span>
                                        <span style={{ marginLeft: '0.5rem' }}>
                                            <Typography variant="body1">or drag and drop</Typography>
                                        </span>
                                    </Box>
                                </label>
                            </Card>
                        </Grid>

                        <input
                            type="file"
                            id="fileInput"
                            required
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            accept=".png, .jpg, .jpeg,.gif"
                        />
                        {/* </form> */}


                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: "1rem" }}>
                    <Button variant="text" onClick={handleDialogClose}>
                        CANCEL
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                        SAVE
                    </Button>
                </DialogActions>
            </Box>

        </>

    )
}

export default ChangeImageProfile