import React, { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { publish } from '../helper/publisher.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const InputDatePicker = ({ label, model, chainUpdatedData, showErrors, modelKey, validation, id, type, sx = {},xs, change = () => { }, value, editMode, version, ...props }) => {
  console.debug("valujfnjwrjkkjwfne", xs)
  // const today = new Date();
  // const defaultDate = today.getFullYear() + '/' + String(today.getMonth()).padStart(2, '0') + '/' + String(today.getDate()).padStart(2, '0');
  const [dateValue, setDateValue] = React.useState(value);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [updatedData, setUpdatedData] = React.useState(chainUpdatedData);
  let today = new Date();
  const [maxDate, setMaxDate] = React.useState({ maxDate: today });
  const [minDate, setMinDate] = React.useState({});
  // const [hardRefresh, setHardRefresh] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    console.log("data Satyam", { updatedData, modelKey })
    let minDateLocal = minDate;
    let maxDateLocal = maxDate;
    if (model[modelKey]?.minDate) {
      let relatedModel = updatedData.filter(v => {
        return v.label == model[modelKey].minDate;
      })[0]
      if (relatedModel.value && relatedModel.value != '') {
        // setMinDate({minDate: new Date(relatedModel.value)});
        minDateLocal = { minDate: new Date(relatedModel.value) }
      } else {
        // setMinDate({});
        minDateLocal = {};
      }
    }
    if (model[modelKey]?.maxDate) {
      let relatedModel = updatedData.filter(v => {
        return v.label == model[modelKey].maxDate;
      })[0];
      // if(model[modelKey].label == 'From Year'){
      console.log("Maa bharti", { minDate: minDate?.mindate, maxDate, trying: new Date(relatedModel.value), related: relatedModel.value, modelLabel: model[modelKey].label })
      // }
      if (relatedModel.value && relatedModel.value != '') {
        // setMaxDate(new Date(relatedModel.value));
        maxDateLocal = { maxDate: new Date(relatedModel.value) }
      } else {
        // setMaxDate(today);
        maxDateLocal = { maxDate: today };
      }
    }
    setMaxDate(maxDateLocal);
    setMinDate(minDateLocal);
    console.log("Maa bharti Min Max Date", { maxDate, minDate, modelLabel: model[modelKey].label });
  }, [updatedData])

  useEffect(() => {
    if (Array.isArray(chainUpdatedData))
      setUpdatedData([...chainUpdatedData])
  }, [chainUpdatedData])

  // useEffect(() => {
  //   console.log("Maa bharti", {hardRefresh,modelLabel: model[modelKey].label})
  //   if(!hardRefresh){
  //     setHardRefresh(true);
  //   }
  // }, [hardRefresh])

  // useEffect(() => {
  //   console.log("Maa bharti", {maxDate, minDate,modelLabel: model[modelKey].label})
  //   setHardRefresh(false);
  // }, [maxDate, minDate])

  useEffect(() => {
    // console.log("Ankur Ka Data", {showErrors})
    if (showErrors) {
      var error = false;
      var errorMessage = false;
      for (let i = 0; i < validation?.length; i++) {
        let res = validation[i](dateValue);
        if (res.error) {
          error = res.error;
          errorMessage = res.errorMessage;
          break;
        }
      }
      setError(error);
      setErrorMessage(errorMessage);
    }
  }, [showErrors]);

  const onClickTextValue = () => {
    if ((dateValue === "") && (model[modelKey].validators[0]?.name === "required")) {
      setError(true)
      setErrorMessage("Field is required")
    }
  }
  const onChangeDateValue = (e) => {
    console.log("Go", { updatedData })
    model[modelKey].value = e;
    setDateValue(e);
    var error = false;
    let errorMessage = false;
    for (let i = 0; i < validation?.length; i++) {
      let res = validation[i](e);
      if (res.error) {
        error = res.error;
        errorMessage = res.errorMessage;
        break;
      }
    }
    setError(error);
    setErrorMessage(errorMessage);
    change(e);

    /*PUBLISHING DATA TO THE TOUCHED HELPER */
    publish({ data: model, id: 'touched' + id });

    /*PUBLISHING DATA TO THE ACTIVETOUCH HELPER */
    publish({ data: model[modelKey], id: 'activeTouch' + id });

    /*PUBLISHING DATA TO THE FILLED HELPER */
    publish({ data: error, id: 'filled' + id });
  }

  return (
    <>
      {/* Value
      {JSON.stringify(dateValue)}
      Max Date
      {JSON.stringify(maxDate)}
      Min Date
      {JSON.stringify(minDate?.minDate ?? 'Nothing')}
      hardRefresh
      {JSON.stringify(hardRefresh)} */}
      {/* {hardRefresh && ( */}
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          key={version + modelKey}
          label={label}
          sx={isMobile ? xs : sx}
          value={!dateValue ? null : dateValue}
          // maxDate={maxDate}
          {...props}
          onChange={onChangeDateValue}
          renderInput={(params) => <TextField sx={isMobile ? xs : sx} onClick={onClickTextValue} {...params} error={error} helperText={errorMessage ? errorMessage : ''} />}
          readOnly={editMode == false}
          {...minDate}
          {...maxDate}
        />
      </LocalizationProvider>
      {/* )} */}
    </>
  )
}

export default InputDatePicker