import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Radio, RadioGroup, FormLabel, FormControl, Grid, Stack, Dialog, DialogContent, CardHeader, IconButton, FormControlLabel, Checkbox, Divider, ListItem, Skeleton } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//Icons
import Avatar from '@mui/material/Avatar';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';
import { AccountCircle, Add, Edit, MoreVert } from '@mui/icons-material';
import ApiService from '../../utils/ApiService';
import SharedDrawer from '../../features/drawers/Drawer';
import AddAllowedIPDrawer from '../../components/AdminDrawers/AddAllowedIPDrawer';
import { useDispatch } from 'react-redux';
import { openDrawer } from '../../features/drawers/drawerSlice';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
import { LoadingButton } from "@mui/lab";

const IpAddress = ({ readOnly }) => {
    const dispatch = useDispatch();

    let genderOptions = [{ value: 'Male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'preferNotToSay', label: 'Prefer not to say' }]
    const [userData, setUserdata] = useState("")
    const [edit, setEdit] = useState(false);
    const [checked, setChecked] = useState(false);
    const [checkedBlackList, setCheckedBlackList] = useState(false);
    const [openIPAddress, setOpenIPAddress] = useState(false);
    const [ipAddressDetails, setIpAddressDetails] = useState([]);
    const [ipDetails, setIpDetails] = useState([]);
    const [updateData, setUpdateData] = useState(false);

    const [ipsList, setIpsList] = useState([]);

    const [allowedMode, setAllowedMode] = useState(''); // State to track selected list


    // const [hardRefresh, setHardRefresh] = useState(true);

    // useEffect(() => {
    //     if(!hardRefresh){
    //         setHardRefresh(true);
    //     }
    // }, [hardRefresh])




    let inputProps = readOnly ? { readOnly: readOnly } : {};

    let allowedIpDetailsModel = [
        { inputProps: inputProps, label: 'IP Name', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[A-Za-z]+$/, errorMessage: 'Field must only contain alphabets' }] }];

    const allowedIPDetailsHelper = new FormHelper({ model: allowedIpDetailsModel, id: 'personalDetails' });
    const openAllowedIPDrawer = () => {
        // dispatch(openDrawer('allowedIp'));
        setOpenIPAddress(!openIPAddress);

    }
    // const ipAddressDetails = [
    //     { id: 1, deviceName: "hola 1", ipAddresss: "192.168.0.1" },
    //     { id: 2, deviceNames: "hola 2", ipAddresss: "192.168.0.2" },
    //     { id: 3, deviceNames: "hola 3", ipAddress: "192.168.0.3" },
    //     // Add more sample data as needed
    // ];
    function apiCalling() {
        ApiService.get('device')
            .then((res) => {
                console.log('Device API response:', res);
                let data = res.data.data
                const responseData = data.allowedIPs || [];
                console.debug("device api response", data);
                if (Array.isArray(responseData)) { // Check if responseData is an array
                    setIpAddressDetails(responseData);
                }

                setIpDetails(data);
                setChecked(data.setting)
                if (data.blacklistEnabled) {
                    setAllowedMode("blacklist")
                    setIpsList(data.disallowedIPs)
                }
                if (data.whitelistEnabled) {
                    setAllowedMode("whitelist")
                    setIpsList(data.allowedIPs)
                }
            })
            .catch((error) => {
                console.error('Error fetching device data:', error);
                setIpAddressDetails([]); // Set to empty array on error
            });
    }
    useEffect(() => {
        apiCalling();
    }, []);

    useEffect(() => {
        apiCalling();
    }, [updateData]);

    function onSubmitHandler(value) {
        let data = new FormData()
        let newValue
        if (checked) {
            newValue = 1
            if (allowedMode) {
                if (allowedMode === "blacklist") {
                    data.append("blacklistEnabledStatus", 1)
                }
                if (allowedMode === "whitelist") {
                    data.append("whitelistEnabledStatus", 1)
                }
            }
        } else {
            newValue = 0
        }

        data.append("settingStatus", newValue)

        // if (allowedMode) {
        //     if (allowedMode === "blacklist") {
        //         // data.append("blacklistEnabledStatus", 1)
        //     }
        //     if (allowedMode === "whitelist") {
        //         // data.append("whitelistEnabledStatus", 1)
        //     }
        // }

        // console.debug("settingStatus", allowedMode)
        ApiService.postAdopter('device', data)
            .then((res) => {
                setUpdateData(true)
                console.log('Device API response:', res);
                const responseData = data.allowedIPs || [];
                console.debug("device api response", responseData);
                if (Array.isArray(responseData)) { // Check if responseData is an array
                    setIpAddressDetails(responseData);
                }
                setIpAddressDetails(responseData);
                // Update the checked status for blacklist and whitelist based on the response
                setIpDetails(data);
            })
            .catch((error) => {
                console.error('Error fetching device data:', error);
                setIpAddressDetails([]); // Set to empty array on error
            });
    }


    const openIPAddressTextfield = () => {
        setOpenIPAddress(!openIPAddress);
    };
    function handleAccessControlChange(event) {
        setChecked(event.target.checked);
    }


    /*  */

    const handleAccessMode = (event) => { // Define handleListChange function
        setAllowedMode(event.target.value);
        if (event.target.value == "blacklist") {
            setIpsList(ipDetails.disallowedIPs)

        }
        if (event.target.value == "whitelist") {
            setIpsList(ipDetails.allowedIPs)

        }


    };



    return (
        <Box>
            <Box >
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Allowed IP Address</Typography>
                    </Box>
                </Stack>

                <Card >
                    {/* {checked ? ( */}
                    <CardContent>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant="outlined" fontWeight="var(--font-weight-5)">Access</Typography>
                            <Divider flexItem orientation="vertical" />
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button color="primary" variant="text">Cancel</Button>

                                <LoadingButton
                                    color="primary"
                                    // loading={loading}
                                    loadingPosition="start"
                                    variant="contained"
                                    // disabled={disableSumbit}
                                    onClick={onSubmitHandler}
                                >
                                    <span> Save </span>
                                </LoadingButton>
                            </Grid>
                        </Box>
                        <Divider />

                        {checked ? (
                            <>
                                {/* Access Control Switch */}
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={checked} onChange={handleAccessControlChange} />} label="Access Control" />
                                    </FormGroup>
                                </Box>
                                {/* Access Mode */}
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography fontWeight="var(--font-weight-5)">Access Mode</Typography>
                                </Box>
                                {/* Radio Buttons for Black/White List */}
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={allowedMode}
                                            onChange={handleAccessMode}
                                            style={{ justifyContent: 'flex-start' }} // Align radio buttons to the left
                                        >
                                            <FormControlLabel value="blacklist" control={<Radio />} label="Black List" />
                                            <FormControlLabel value="whitelist" control={<Radio />} label="White List" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                {/* IP Address Details */}
                                <Box>
                                    {ipsList ? (
                                        ipsList.map((data, index) => (
                                            <Box key={index} sx={{ p: 2, py: 1 }}>
                                                <Typography variant="subtitle1" color="text.primary" sx={{ pb: 2 }}>Device {index + 1}</Typography>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item xs={4} sx={{ mb: 3 }}>
                                                        <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Device Name</Typography>
                                                        <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>
                                                            {data.deviceName ? data.deviceName : `Device ${index + 1}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4} sx={{ mb: 3 }}>
                                                        <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>IP Address</Typography>
                                                        <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>
                                                            {data ? data : `IP Address Not found ${index + 1}`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </Box>
                                        ))
                                    ) : (
                                        // Skeleton Loading
                                        [1, 2].map(d => (
                                            <ListItem key={d}>
                                                <Skeleton variant="text" sx={{ width: "100%", height: 40 }} />
                                            </ListItem>
                                        ))
                                    )}
                                    {/* Add Allowed IP Address Button or Drawer */}
                                    {openIPAddress ? (
                                        <AddAllowedIPDrawer parentId='mobile' drawerHeading={"Add Mobile Number"} setOpenIPAddress={setOpenIPAddress} />
                                    ) : (
                                        <Box textAlign={"center"}>
                                            <Button startIcon={<Add />} onClick={openAllowedIPDrawer}> ADD ALLOWED IP ADDRESS</Button>
                                        </Box>
                                    )}
                                </Box>
                            </>
                        ) : (
                            // Access Control Switch when Disabled
                            <FormControlLabel control={<Switch checked={checked} onChange={handleAccessControlChange} />} label="Access Control" />
                        )}
                    </CardContent>

                    {/* ) : (
                        <FormControlLabel control={<Switch checked={checked} onChange={handleAccessControlChange} />} label="Access Control" /> // Render unchecked switch if access control is disabled
                    )} */}
                </Card>

            </Box>
        </Box>
    )
}
export default IpAddress