import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Grid, Stack, CardHeader, List, ListItem, ListItemButton, Link, Divider, Badge } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import { Add, ArrowForward, Delete, Edit, School, WorkspacePremium, } from '@mui/icons-material';
import { openDrawer } from '../../features/drawers/drawerSlice';
import AddEducationDrawer from '../../components/AdminDrawers/AddEducationDrawer';
import AddCertificateDrawer from '../../components/AdminDrawers/AddCertificateDrawer';
import SharedDrawer from '../../features/drawers/Drawer';
import SharedDialog from '../../features/dialogBox/dialogBox';
import { openDialog, closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EducationCertificate = ({ readOnly }) => {
    const dispatch = useDispatch();
    const [hardRefresh, setHardRefresh] = useState(true);
    const userEducationDetails = useSelector((state) => state?.user?.userListName?.userEducation);
    const userCertificationDetails = useSelector((state) => state?.user?.userListName?.certificate);
    const [curerentItemToDelete, setCurerentItemToDelete] = useState({});
    const [currentEditEducationIndex, setCurrentEditEducationIndex] = useState();
    const [currentEditCertificateIndex, setCurrentEditCertificateIndex] = useState();
    const [openEducational, setOpenEducational] = useState(false);
    const [openCertificate, setOpenCertificate] = useState(false);
    const [expandedAccordion, setExpandedAccordion] = useState(null); 

    const openEducationalTextfield = () => {
        setOpenEducational(!openEducational)
    }

    // const openCertificateTextfield = () => {
    //     setOpenEducational(!openEducational)
    // }
    console.debug("userCertificationDetails", userCertificationDetails)
    useEffect(() => {
        if (!hardRefresh) {
            setHardRefresh(true);
        }
        window.scrollTo(0, 0);
    }, [hardRefresh])
    const openCertificateDrawer = () => {
        // setCurrentEditCertificateIndex(-1);

        // dispatch(openDrawer('certificate'));
        setOpenCertificate(!openCertificate)
    }
    const deleteHandler = () => {
        if (!curerentItemToDelete.type) {
            return;
        }
        switch (curerentItemToDelete.type) {
            case 'userEducationDetails': console.log("Look here", { userEducationDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
            case 'userCertificationDetails': console.log("Look here", { userCertificationDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
        }
    }
  
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };
    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Education & Certifications</Typography>
                    </Box>
                </Stack>
                <Card sx={openEducational ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                    <Accordion  expanded={expandedAccordion === 'panel1'} onChange={handleAccordionChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <CardHeader
                                sx={{ pl: 1 }}
                                avatar={<School color={expandedAccordion === 'panel1'?'primary':""}/>}
                                subheader="Manage and upload your educational details"
                                title={<Typography variant='body1' fontWeight="var(--font-weight-5)">Educational Details</Typography>}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <List sx={{ pt: 0 }}>
                                {userEducationDetails?.map((data, index) => (
                                    <>
                                        <Box sx={{ px: 2, py: 1 }}>
                                            <Typography variant="subtitle1" color="text.primary" sx={{ pb: 2 }}>Education {index + 1}</Typography>
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>University/Board</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.universityName}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Degree</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.degree}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Field Of Study</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.fieldOfStudy}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Percentage</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}> {data.CGPA}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >From Year</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.fromYear}</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >To Year</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.toYear}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Box>
                                    </>
                                ))}
                            </List>
                            {openEducational ?
                                <AddEducationDrawer parentId='education' currentEditEducationIndex={currentEditEducationIndex} setOpenEducational={setOpenEducational} />
                                :
                                <Box textAlign={"center"}>
                                    <Button startIcon={<Add />} variant="outlined" onClick={openEducationalTextfield}> ADD EDUCATION DETAILS</Button>
                                </Box>
                            }

                        </AccordionDetails>
                    </Accordion>
                </Card>

                {/* Certificate Details */}

                <Card sx={openCertificate ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                    <Accordion expanded={expandedAccordion === 'panel2'} onChange={handleAccordionChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <CardHeader
                                sx={{ pl: 1 }}
                                avatar={<WorkspacePremium color={expandedAccordion === 'panel2'?'primary':""}/>}
                                subheader="Manage and upload your certificate"
                                title={<Typography variant='body1' fontWeight="var(--font-weight-5)">Certificate Details</Typography>}
                            />
                        </AccordionSummary>
                        <AccordionDetails>

                            <List sx={{ pt: 0 }}>
                                {userCertificationDetails?.map((data, index) => (
                                    <>
                                        <Box sx={{ px: 2, py: 1 }}>
                                            <Typography variant="subtitle1" color="text.primary" sx={{ pb: 2 }}>Certificate {index + 1}</Typography>
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Certificate Name</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.title}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Description</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.description}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Certificate Url/Image</Typography>
                                                    <Typography variant='overline' fontWeight="var(--font-weight-5)" sx={{ mt: 1 }}><Link href={data.file} target="_blank">SHOW CREDENTIALS <ArrowForward sx={{ height: "15px" }} /></Link></Typography><br />
                                                </Grid>
                                                {/* <Grid item xs={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Issue  Date</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>Percentage/CGPA: {data.CGPA}</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ mb: 3 }}>
                                                    <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Expiration Date</Typography>
                                                    <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.fromYear}</Typography>
                                                </Grid> */}

                                            </Grid>
                                            <Divider />
                                        </Box>
                                    </>
                                ))}
                            </List>
                            {openCertificate ?
                                <AddCertificateDrawer parentId='certificate' currentEditCertificateIndex={currentEditCertificateIndex} setOpenCertificate={setOpenCertificate} />
                                :
                                <Box textAlign={"center"}>
                                    <Button startIcon={<Add />} variant="outlined" onClick={openCertificateDrawer}>ADD CERTIFICATE</Button>
                                </Box>
                            }


                        </AccordionDetails>
                    </Accordion>
                </Card>

                {/* Delete Dailog Box Code  */}
                <SharedDialog id="deleteDialog">
                    <Box pb="1rem">
                        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
                        <DialogContent sx={{ padding: '16px 24px' }}>
                            <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                                This will be deleted forever and you won't be able to restore it.
                                {/* {methodsAndState.dynamicDeleteMessage} */}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: '16px 24px' }}>
                            <Button onClick={() => dispatch(closeDialog('deleteDialog'))}>CANCEL</Button>
                            <Button onClick={deleteHandler} color='primary' variant="contained">YES, DELETE</Button>
                        </DialogActions>
                    </Box>
                </SharedDialog>
            </Box>
        </Box>
    );

}

export default EducationCertificate