import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { publish } from '../helper/publisher.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import Box from '@mui/material/Box';

const InputTextField = ({ value, label, showErrors, model, chainUpdatedData, modelKey, validation, id, type, sx, xs, multiline, rows, change = () => { }, editMode, version, ...props }) => {
  console.debug("Jiren textFeild", { value, label, version: version })
  const [textValue, setTextValue] = useState(value);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const theme = useTheme();
  // const smallScreen = theme.breakpoints.down('sm');
  useEffect(() => {
    // console.log("Ankur Ka Data", {showErrors})
    if (showErrors) {
      var error = false;
      var errorMessage = false;
      for (let i = 0; i < validation?.length; i++) {
        var res = validation[i](textValue);
        if (res.error) {
          error = res.error;
          errorMessage = res.errorMessage;
          break;
        }
      }
      setError(error);
      setErrorMessage(errorMessage);
    }
  }, [showErrors]);

  //This function enables the functionality of showing the error once the textField is clicked by checking if the field the empty and if the field contains "required" as validator or not
  const onClickTextValue = () => {
    // if ((textValue === "") && (model[modelKey].validators?.[0]?.name === "required")) {
    //   setError(true)
    //   setErrorMessage("Field is required")
    // }
  }

  const onChangeTextValue = (e) => {
    setTextValue(e.target.value);
    model[modelKey].value = e.target.value;
    var error = false;
    var errorMessage = false;
    for (let i = 0; i < validation?.length; i++) {
      var res = validation[i](e.target.value);
      if (res.error) {
        error = res.error;
        errorMessage = res.errorMessage;
        break;
      }
    }
    setError(error);
    setErrorMessage(errorMessage);
    change(e.target.value);
    /*PUBLISHING DATA TO THE TOUCHED HELPER */
    publish({ data: model, id: 'touched' + id });
    /*PUBLISHING DATA TO THE ACTIVETOUCH HELPER */
    publish({ data: model[modelKey], id: 'activeTouch' + id });

    /*PUBLISHING DATA TO THE FILLED HELPER */
    publish({ data: error, id: 'filled' + id });
  }
console.debug("isMobile",sx)
  return (
    <>
      <TextField
        key={version + modelKey}
        rows={rows}
        multiline={multiline}
        value={textValue}
        label={label}
        onClick={onClickTextValue}
        onChange={onChangeTextValue}
        error={error}
        helperText={errorMessage ? errorMessage : ''}
        {...props}
        InputProps={{
          readOnly: (editMode == false),
        }}
        sx={isMobile?xs:sx}
        // sx={smallScreen?xs:sx}
      >
      </TextField>
    </>
  )
}

export default InputTextField