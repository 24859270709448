import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import { Box, Grid, Typography, Button, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types';
import BasicInformationData from '../../datafiles/AdminDashboard/basicInformationDummyData.json'
import RenderHelper from "../../form/ReactiveForm";
import FormHelper from "../../form/FormHelper";
import ApiService from "../../utils/ApiService";
import { setSnackBar } from "../../features/snakebar/snakebar";
import { setUserInfo } from "../../features/userInfoStore/userSlice";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";

let tempBI = BasicInformationData
localStorage.setItem("tempBI", JSON.stringify(tempBI))
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
//Component code starts here
const AddBankDrawer = ({ id, parentId, drawerHeading , cities, countries, states,setOpenBank,openBank}) => {
    const userData = useSelector((state) => state?.user?.userListName);
    const userDstate = useSelector((state) => console.debug("statejhfhe",state));
  
    const [loading,setLoading]=useState(false)
    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        setOpenBank(!openBank)

        // dispatch(closeDrawer(parentId));
    };
    let inputProps = {};
    let nummberTypes = [{ value: 'Home', label: 'Home' }, { value: 'Personal', label: 'Personal' }, { value: 'Official', label: 'Official' }];
    let addressTypes = [{ value: 'Present', label: 'Present' }, { value: 'Permanent', label: 'Permanent' }];

    let mobileDetailsModel = [
        { inputProps: inputProps, label: 'Bank Name', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 },validators: [{ name: 'required' }, { name: 'length', min: 5 }], },
        { inputProps: inputProps, label: 'Branch Address', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[a-zA-Z0-9\s\-_',.()&]*$/, errorMessage: "Field must only contain letters, numbers, spaces, -, ', ,, ., (, ), &." }] },
        { inputProps: inputProps, label: 'Account No.', type: "textField", value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern',  regex: /^[a-zA-Z0-9]{11,16}$/, errorMessage: 'Field must contain 11 to 16 Characters, alphanumeric' }]},
        { inputProps: inputProps, label: 'IFSC Code', type: "textField", value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' },{ name: 'pattern',  regex: /^[a-zA-Z0-9]{11}$/, errorMessage: "IFSC Code must contain exactly 11 Characters, alphanumeric." }]},
        { inputProps: inputProps, label: 'UAN No.', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' },{ name: 'pattern', regex: /^[0-9]{12}$/, errorMessage: "UAN Code must contain exactly 12 numbers." }], variant: "outlined", },
        { inputProps: inputProps, label: 'PF No.', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 }, validators: [{ name: 'required' },{ name: 'pattern', regex: /^[a-zA-Z0-9]{22}$/, errorMessage: "PF No must contain exactly 22 Characters, alphanumeric." }] },
    ];
    const candidateDetailsModel = new FormHelper({ model: mobileDetailsModel, id: 'contactDetails' });
    const [candidateDetailsHelperData, setCandidateDetailsHelperData] = useState(mobileDetailsModel);
    const [showErrors, setShowErrors] = useState(0);

    
    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }
    const addBankDetail = () => {
        let dataCandidateDetalsHelper = candidateDetailsHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);

        var error = false;
        var errorMessage = false;
       
        for (let i = 0; i < candidateDetailsHelperData.length; i++) {
            for (let j = 0; j < candidateDetailsHelperData[i]?.validation?.length; j++) {
                var res = candidateDetailsHelperData[i]?.validation[j](candidateDetailsHelperData[i].value);
                if (res.error) {
                    error = res.error;
                    errorMessage = res.errorMessage;
                    break;
                }
            }
        }
        if(error){
            setShowErrors(showErrors + 1);
            return;
        }

        let formData = new FormData();
        // formData.append('address', dataCandidateDetalsHelper['Address Type']);
        formData.append('bankName', dataCandidateDetalsHelper['Bank Name']);
        formData.append('addressOfBranch', dataCandidateDetalsHelper['Branch Address']);
        formData.append('uanNo', dataCandidateDetalsHelper['UAN No.']);
        formData.append('accountNo', dataCandidateDetalsHelper['Account No.']);
        formData.append('ifscCode', dataCandidateDetalsHelper['IFSC Code']);
        formData.append('pfNo', dataCandidateDetalsHelper['PF No.']);
        // formData.append('country', dataCandidateDetalsHelper['Pincode']);
        formData.append('userId', userData.userId);
        console.log({dataCandidateDetalsHelper})
        setLoading(true)
        ApiService.postAdopter('bankDetailUpdate', formData).then((res) => {
            console.log({ res })
            if (res) {
                setOpenBank(false)
                // handleDrawerClose()
                getUserData()
                if (res.status == 200) {
                    dispatch(setSnackBar({ dataLoad: true, message: "Data added successfully", severity: "success" }))
                    setLoading(false)
                }
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            setLoading(false)
        })

       
    }
    return (<>
        <Stack >
            <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={5}>
                        <Box display='flex'>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Add Bank Details </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" size="small" sx={{ mx: 1 }} onClick={handleDrawerClose}><Typography variant="body2">CANCEL</Typography></Button>

                     <LoadingButton
                        color="primary"
                        loading={loading}
                        size="small"
                        loadingPosition="start"
                        startIcon={<Save />}
                        variant="contained"
                        // disabled={disableSumbit}
                        onClick={addBankDetail}
                    >
                        <span>SAVE</span>
                    </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
        <Box >
            <Box sx={{ width: 1 }}>
                <Box component="form" p={1} sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => { console.log("Hind Ki Chadar", { data }); setCandidateDetailsHelperData(data) }} {...candidateDetailsModel.model} />
                </Box>
                {/* <Box display={"flex"} sx={{ p: 2 }}>
                    <LoadingButton
                        color="primary"

                        loading={loading}
                        loadingPosition="start"
                        startIcon={<Save />}
                        variant="contained"
                        // disabled={disableSumbit}
                        onClick={addBankDetail}
                    >
                        <span>SAVE</span>
                    </LoadingButton>
                    <Button variant="outlined" sx={{ mx: 1 }} onClick={handleDrawerClose}><Typography variant="body2">CANCEL</Typography></Button>
                </Box> */}
            </Box>
        </Box>
    </>)
}
export default AddBankDrawer;