import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import { Box, Grid, Typography, Button, Stack, IconButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types';
import BasicInformationData from '../../datafiles/AdminDashboard/basicInformationDummyData.json'
import RenderHelper from "../../form/ReactiveForm";
import FormHelper from "../../form/FormHelper";
import ApiService from "../../utils/ApiService";
import { setSnackBar } from "../../features/snakebar/snakebar";
import { setUserInfo } from "../../features/userInfoStore/userSlice";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";

let tempBI = BasicInformationData
localStorage.setItem("tempBI", JSON.stringify(tempBI))
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const AddEmploymentDrawer = ({ id, parentId, setOpenEmployment, currentEditEmploymentIndex }) => {
    const [loading, setLoading] = useState(false);
    const userData = useSelector((state) => state?.user?.userListName);

    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        // console.debug("close : parentId ", parentId)
        // dispatch(closeDrawer(parentId));
        setOpenEmployment(false)
    };

    const [showErrors, setShowErrors] = useState(0);

    let employmentDetailsModel = [
        { label: 'Job Title', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 },  xs: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }] },
        { label: 'Job Type', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3 ', m: 1 },xs: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }] },
        { label: 'Company Name', type: "textField", value: '', sx: { gridColumn: 'span 3 ', m: 1 },xs: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }], },
        { label: 'Location', type: "textField", value: '', sx: { gridColumn: 'span 3', m: 1 },xs: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[a-zA-Z0-9\s\-_',.()&]*$/, errorMessage: "Field must only contain letters, numbers, spaces, -, ', ,, ., (, ), &." }] },
    ];
    let employmentStartYearModel = [
        { label: 'Month', views: ['month'], type: "dateField", size: ' ', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }], variant: "outlined", },
        { label: 'Year', views: ['year'], type: "dateField", size: ' ', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }] },
    ];
    let employmentEndYearModel = [
        { label: 'Month', views: ['month'], type: "dateField", size: ' ', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }], variant: "outlined", },
        { label: 'Year', views: ['year'], type: "dateField", size: ' ', value: '', sx: { gridColumn: 'span 6 ', m: 1 }, validators: [{ name: 'required' }] },
    ];
    const employmentDetailsHelper = new FormHelper({ model: employmentDetailsModel, id: 'personalDetails' });
    const employmentStartHelper = new FormHelper({ model: employmentStartYearModel, id: 'personalDetails' });
    const employmentEndHelper = new FormHelper({ model: employmentEndYearModel, id: 'personalDetails' });

    const [employmentDetailsHelperData, setEmploymentDetailsHelperData] = useState(employmentDetailsModel);
    const [employmentStartHelperData, setEmploymentStartHelperData] = useState(employmentStartYearModel);
    const [employmentEndHelperData, setEmploymentEndHelperData] = useState(employmentEndYearModel);

    const userEmploymentData = useSelector((state) => state?.drawer?.data); // {} by default
    console.debug("userEmploymentData", userEmploymentData)
    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }
    const addContactDetail = () => {
        console.log({ employmentDetailsHelperData })
        let dataEmploymentDetailsHelper = employmentDetailsHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);
        let dataEmploymentStartHelper = employmentStartHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);
        let dataEmploymentEndHelper = employmentEndHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);
        // dataEmploymentStartHelper['Month'] = dataEmploymentStartHelper['Month']['$M'];
        // dataEmploymentStartHelper['Year'] = dataEmploymentStartHelper['Year']['$y'];
        // dataEmploymentEndHelper['Month'] = dataEmploymentEndHelper['Month']['$M'];
        // dataEmploymentEndHelper['Year'] = dataEmploymentEndHelper['Year']['$y'];
        // console.log({ dataEmploymentDetailsHelper, dataEmploymentStartHelper, dataEmploymentEndHelper })


        var error = false;
        var errorMessage = false;

        for (let i = 0; i < employmentDetailsHelperData.length; i++) {
            for (let j = 0; j < employmentDetailsHelperData[i]?.validation?.length; j++) {
                var res = employmentDetailsHelperData[i]?.validation[j](employmentDetailsHelperData[i].value);
                if (res.error) {
                    error = res.error;
                    errorMessage = res.errorMessage;
                    break;
                }
            }
        }
        if (error) {
            setShowErrors(showErrors + 1);
            return;
        }
        setLoading(true)

        let formData = new FormData();
        formData.append('previousCompanyName', dataEmploymentDetailsHelper['Company Name']);
        formData.append('officeAddress', dataEmploymentDetailsHelper['Location']);
        formData.append('designation', dataEmploymentDetailsHelper['Job Title']);
        formData.append('department', dataEmploymentDetailsHelper['Job Type']);
        formData.append('userId', userData.userId);

        // formData.append('department: ', employmentDetailsHelperData);
        // formData.append('reasonForLeave: ', employmentDetailsHelperData);
        // formData.append('StartMonth: ', employmentDetailsHelperData);
        // formData.append('StartYear: ', employmentDetailsHelperData);
        // formData.append('CurrentlyWorkingHere: ', employmentDetailsHelperData);
        // formData.append('EndMonth: ', employmentDetailsHelperData);
        // formData.append('EndYear: ', employmentDetailsHelperData);
        ApiService.postAdopter('userEmployementUpdate', formData).then((res) => {
            if (res) {
                handleDrawerClose()
                getUserData()
                if (res.status == 200) {
                    dispatch(setSnackBar({ dataLoad: true, message: "Data added successfully", severity: "success" }))
                    setLoading(false)
                }
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

    }
    useEffect(() => {
        if (userEmploymentData) {
            employmentDetailsHelper.set({
                'Job Title': userEmploymentData.department,
                'Job Type': userEmploymentData.designation,
                'Company Name': userEmploymentData.previousCompanyName,
                'Location': userEmploymentData.officeAddress,
            }, () => {
                // personalDetailsHelper.model = updatedModel;
            })

        }
    }, [userEmploymentData])

    return (<>
        <Stack >
            <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={5.5}>
                        <Box display='flex'>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Add Employment Details</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" size="small" sx={{ mx: 1 }} onClick={handleDrawerClose}><Typography variant="body2">CANCEL</Typography></Button>

                        <LoadingButton
                            color="primary"
                            loading={loading}
                            size="small"
                            loadingPosition="start"
                            startIcon={<Save />}
                            variant="contained"
                            // disabled={disableSumbit}
                            onClick={addContactDetail}
                        >
                            <span>SAVE</span>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
        <Box>
            <Box sx={{ width: 1 }}>
                <Box component="form"  sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => { console.log("Hind Ki Chadar", { data }); setEmploymentDetailsHelperData(data) }} {...employmentDetailsHelper.model} />
                </Box>


            </Box>
        </Box>
    </>)
}

export default AddEmploymentDrawer;