import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography, Stack, IconButton, ListItem, List, Link, FormControl, InputLabel, OutlinedInput, InputAdornment, TextField, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Update, Visibility, VisibilityOff } from '@mui/icons-material';
import ApiService from '../../utils/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBar } from '../../features/snakebar/snakebar';
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";

const Password = ({ readOnly }) => {
    const passwordRegex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\[\]\\\\]).{8,}$"
    const passwordValidationText = [{ id: 1, text: "Password Length matters;at least 8 characters" },
    { id: 2, text: "Add some confusion; include special characters and/or numbers" },
    { id: 3, text: "Don't only play small ball; at least one uppercase character" },
    { id: 4, text: "Don't go all big either; at least one lowercase character" }]
    const userData = useSelector((state) => state?.user?.userListName);
    const [isEditable, setIsEditable] = useState(false);

    console.debug("userDatasdfjejksf", userData)

    const openIsEditable = () => {
        setIsEditable(!isEditable)
    }

    const handleChangePasswordClose = () => {
        setIsEditable(false)
    };
    const [currentPassword, setCurrentPassword] = useState("")
    const [currentPasswordErr, setCurrentPasswordErr] = useState(false)
    const passwordFormat = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    const [newPassword, setNewPassword] = useState("")
    const [newPasswordErr, setNewPasswordErr] = useState(false)

    const [confirmPassword, setConfirmPassword] = useState("")
    const [confirmPasswordErr, setConfirmPasswordErr] = useState(false)




    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [disabled, setDisabled] = useState(false);


    const dispatch = useDispatch();
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

    useEffect(function () {
        window.scrollTo(0, 0);
    }, [])

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    function onSubmitHandler() {
        let data = {}
        if (!currentPassword) {
            setCurrentPasswordErr(true)
            // return;
        }
        if (!confirmPassword) {
            setConfirmPasswordErr(true)
            // return;
        }
        if (!newPassword) {
            setNewPasswordErr(true)
            // return;
        }

        data["c_password"] = confirmPassword;
        data["new_password"] = newPassword;
        data["password"] = currentPassword;
        if (confirmPassword && newPassword && currentPassword && newPassword === confirmPassword) {
            setDisabled(true);
            ApiService.put('resetPassword', data, userData.userId).then(res => {
                let data = res.data.data;
                setDisabled(false);
                // setUserdata(data)
                dispatch(setSnackBar({ dataLoad: true, message: "Password Reset Successfully", severity: "success" }))
            }).catch((error) => {
                console.log(error);
                setDisabled(false);
                dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            })
        }
    }
    function onChangeHandler(e) {
        if (e.target.name == "currentPassword") {
            setCurrentPassword(e.target.value)
            setCurrentPasswordErr(false)
        }
        if (e.target.name == "newPassword") {
            setNewPassword(e.target.value)
            setNewPasswordErr(false)

        }
        if (e.target.name == "confirmPassword") {
            setConfirmPassword(e.target.value)
            setConfirmPasswordErr(false)
        }
    }
    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Password</Typography>
                    </Box>
                </Stack>
                <Card >
                    <CardContent sx={{ p: 4 }}>
                        {/* <Box display={'flex'} justifyContent={'space-between'}> */}
                        <Grid container >
                            <Grid item lg={8} md={6} mb={1} >
                            <Typography>Set a strong password to prevent unauthorized access to your account</Typography>
                            </Grid>
                            <Grid item lg={4} md={6} mb={1} display="flex" justifyContent="flex-end">
                            {isEditable && (<>
                                    <Button variant="outlined" size="small" onClick={handleChangePasswordClose} sx={{mr:1}}><Typography variant="body2">CANCEL</Typography></Button>
                                    <LoadingButton
                                        color="primary"
                                        size="small"
                                        // loading={loading}
                                        loadingPosition="start"
                                        variant="contained"
                                        // disabled={disableSumbit}
                                        onClick={onSubmitHandler}
                                    >
                                        <span>CHANGE PASSWORD</span>
                                    </LoadingButton>
                                </>
                                )}
                            </Grid>
                        </Grid>
                        {/* <Grid container display={'flex'}>
                            <Grid item xs={12} md={6} lg={8} mb={2}>
                                <Typography>Set a strong password to prevent unauthorized access to your account</Typography>
                            </Grid>
                            <Grid xs={12} md={6} mb={2} lg={2} display={'flex'} justifyContent={'flex-end'}>
                                {isEditable && (<>
                                    <Button variant="outlined" size="small" onClick={handleChangePasswordClose} sx={{mr:1}}><Typography variant="body2">CANCEL</Typography></Button>
                                    <LoadingButton
                                        color="primary"
                                        size="small"
                                        // loading={loading}
                                        loadingPosition="start"
                                        variant="contained"
                                        // disabled={disableSumbit}
                                        onClick={onSubmitHandler}
                                    >
                                        <span>CHANGE PASSWORD</span>
                                    </LoadingButton>
                                </>
                                )}
                            </Grid>
                        </Grid> */}


                        {/* </Box> */}
                        {isEditable ? (
                            <>

                                <Box component="form" sx={{
                                    display: 'grid',
                                    mt: 3,
                                    width: "100%", // or specify the width in pixels or other units as needed
                                    rowGap: "var(--row-gap)", // Make sure --row-gap is defined somewhere
                                    columnGap: "var(--column-gap)", // Make sure --column-gap is defined somewhere
                                    gridTemplateColumns: 'repeat(1, 1fr)', // Default for xs breakpoint
                                    '@media (min-width: 768px)': {
                                        gridTemplateColumns: 'repeat(3, 1fr)', // Adjust for md breakpoint and above
                                    },
                                }}>
                                    <FormControl variant="outlined">
                                        <TextField
                                            name="currentPassword"
                                            type={showCurrentPassword ? 'text' : 'password'}
                                            onChange={onChangeHandler}
                                            error={currentPasswordErr ? true : (currentPassword && !currentPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\\[\]\\\\]).{8,}$/) ? true : false)}
                                            helperText={currentPasswordErr ? "Field is required" : (currentPassword && !currentPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\\[\]\\\\]).{8,}$/) ? "Password format is invalid" : "")}
                                            InputProps={{
                                                pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\\[\\]\\\\]).{8,}",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowCurrentPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            label="Current Password*"
                                        />
                                    </FormControl>
                                </Box>
                                <Box textAlign={"end"} sx={{
                                    display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', '@media (min-width: 768px)': {
                                        gridTemplateColumns: 'repeat(3, 1fr)', // Adjust for md breakpoint and above
                                    },
                                }}>
                                    <Link href="/forgot-password" sx={{ mr: 1.5, mt: 1 }} fontSize={"small"}>Forgot Password?</Link>
                                </Box>
                                <Box component="form" sx={{
                                    display: 'grid', my: 3, gridTemplateColumns: 'repeat(1, 1fr)', '@media (min-width: 768px)': {
                                        gridTemplateColumns: 'repeat(3, 1fr)', // Adjust for md breakpoint and above
                                    }, width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)"
                                }}>
                                    <FormControl variant="outlined">
                                        <TextField
                                            // id="outlined-adornment-password"
                                            name="newPassword"
                                            type={showNewPassword ? 'text' : 'password'}
                                            onChange={onChangeHandler}
                                            // error={newPasswordErr ? true : (newPassword && !newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\\[\]\\\\]).{8,}$/) ? true : false)}
                                            error={newPasswordErr ? true : (newPassword && !passwordFormat.test(newPassword) ? true : false)}
                                            helperText={newPasswordErr ? "Field is required" : (newPassword && !passwordFormat.test(newPassword) ? "Password format is invalid" : "")}
                                            // helperText={newPasswordErr ? "Field is required" : (newPassword && !newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\\[\]\\\\]).{8,}$/) ? "Password format is invalid" : "")}
                                            InputProps={{
                                                pattern: "^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowNewPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}

                                            label="New Password*"
                                        />
                                    </FormControl>
                                </Box>
                                <Box component="form" sx={{
                                    display: 'grid', my: 3, gridTemplateColumns: 'repeat(1, 1fr)', '@media (min-width: 768px)': {
                                        gridTemplateColumns: 'repeat(3, 1fr)', // Adjust for md breakpoint and above
                                    }, width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)"
                                }}>
                                    <FormControl variant="outlined">
                                        <TextField
                                            name="confirmPassword"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            onChange={onChangeHandler}
                                            error={confirmPasswordErr ? true : (confirmPassword && !confirmPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\\[\]\\\\]).{8,}$/) ? true : (newPassword !== confirmPassword ? true : false))}
                                            helperText={confirmPasswordErr ? "Field is required" : (confirmPassword && !confirmPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\\[\]\\\\]).{8,}$/) ? true : (newPassword !== confirmPassword ? "Confirm password not matched with new password" : ""))}
                                            InputProps={{
                                                pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\\[\\]\\\\]).{8,}",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}

                                            label="Confirm Password*"
                                        />
                                    </FormControl>
                                </Box>
                                {/* <Button variant="contained" sx={{ my: 2 }} onClick={onSubmitHandler} disabled={disabled} ><Typography variant="body2">CHANGE PASSWORD</Typography></Button> */}
                                <Box sx={{ px: 3 }}>
                                    <List sx={{ listStyleType: 'disc' }}>
                                        {passwordValidationText.map((data, index) => <ListItem sx={{ display: 'list-item', p: 0 }} key={index}><Typography variant='caption' fontWeight="var(--font-weight-4)" >{data.text}</Typography></ListItem>)}
                                    </List>
                                </Box>
                            </>
                        ) :
                            <>
                                <Box sx={{ my: 3 }}>
                                    <Grid item xs={4}>
                                        <Box>
                                            <Typography variant="subtitle2" color="text.secondary">
                                                Current Password
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle2" color="text.primary">
                                                ********
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box display={'flex'} justifyContent={'center'}>
                                    <Button startIcon={<Update />} variant="outlined" sx={{ my: 2 }} onClick={openIsEditable} disabled={disabled}  ><Typography variant="body2">CHANGE PASSWORD</Typography></Button>
                                </Box>
                            </>
                        }
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )

}

export default Password