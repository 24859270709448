import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import { Box, Grid, Avatar, Typography, Rating, Button, Tabs, Tab, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types';
import BasicInformationData from '../../datafiles/AdminDashboard/basicInformationDummyData.json'
import RenderHelper from "../../form/ReactiveForm";
import FormHelper from "../../form/FormHelper";
import ApiService from "../../utils/ApiService";
import { setSnackBar } from "../../features/snakebar/snakebar";
import { setUserInfo } from "../../features/userInfoStore/userSlice";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

let tempBI = BasicInformationData

localStorage.setItem("tempBI", JSON.stringify(tempBI))

//Tab Panels Code
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

//Component code starts here
const AddEducationDrawer = ({ id, parentId, setOpenEducational, currentEditEducationIndex }) => {
    console.debug("currentEditEducationIndex", currentEditEducationIndex)
    const [loading, setLoading] = useState(false);

    const userData = useSelector((state) => state?.user?.userListName);
    const userEducationData = useSelector((state) => state?.drawer?.data); // {} by default
    const [showErrors, setShowErrors] = useState(0);

    console.debug("close : id ", id)
    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        // console.debug("close : parentId ", parentId)
        // dispatch(closeDrawer(parentId));
        setOpenEducational(false)
    };
    let inputProps = {};

    let educationDetailsModel = [
        { inputProps: inputProps, label: 'University/Board Name', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3', m: 1 }, xs: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }, { name: 'length', min: 5 }] },
        { inputProps: inputProps, label: 'Degree', type: "textField", size: ' ', value: '', sx: { gridColumn: 'span 3', m: 1 }, xs: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?! )[a-zA-Z. -]+$/, errorMessage: 'Field can only contain alphabet values with specific "." or "-" character.' }] },
        { inputProps: inputProps, label: 'Field of study', type: "textField", value: '', sx: { gridColumn: 'span 3', m: 1 }, xs: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?! )[a-zA-Z ]+$/, errorMessage: 'Field must only contain alphabets' }], },
        { inputProps: inputProps, label: 'CGPA/Percentage', type: "textField", value: '', sx: { gridColumn: 'span 3', m: 1 }, xs: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^(?! )[0-9.%]+$/, errorMessage: 'Field can only contain numerical values with "." or "%" characters.' }] },
        { inputProps: inputProps, label: 'From Year', type: "dateField", size: ' ', value: '', sx: { gridColumn: 'span 3', m: 1 }, xs: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }], variant: "outlined", views: ['year'], maxDate: 'To Year' },
        { inputProps: inputProps, label: 'To Year', type: "dateField", size: ' ', value: '', sx: { gridColumn: 'span 3', m: 1 }, xs: { gridColumn: 'span 6', m: 1 }, validators: [{ name: 'required' }], views: ['year'], minDate: 'From Year' },
    ];
    const educationDetailsHelper = new FormHelper({ model: educationDetailsModel, id: 'educationDetails' });


    const [candidateDetailsHelperData, setCandidateDetailsHelperData] = useState(educationDetailsModel);

    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }
    const addContactDetail = () => {
        let dataCandidateDetalsHelper = candidateDetailsHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);
        // dataCandidateDetalsHelper['From Year'] = dataCandidateDetalsHelper['From Year']['$y'];
        // dataCandidateDetalsHelper['To Year']   = dataCandidateDetalsHelper['To Year']['$y'];
        console.log({ dataCandidateDetalsHelper })

        var error = false;
        var errorMessage = false;

        for (let i = 0; i < candidateDetailsHelperData.length; i++) {
            for (let j = 0; j < candidateDetailsHelperData[i]?.validation?.length; j++) {
                var res = candidateDetailsHelperData[i]?.validation[j](candidateDetailsHelperData[i].value);
                if (res.error) {
                    error = res.error;
                    errorMessage = res.errorMessage;
                    break;
                }
            }
        }
        if (error) {
            setShowErrors(showErrors + 1);
            return;
        }


        setLoading(true)
        let formData = new FormData();
        formData.append('degree', dataCandidateDetalsHelper['Degree']);
        formData.append('universityName', dataCandidateDetalsHelper['University/Board Name']);
        formData.append('fieldOfStudy', dataCandidateDetalsHelper['Field of study']);
        formData.append('fromYear', dataCandidateDetalsHelper['From Year']['$y']);
        formData.append('toYear', dataCandidateDetalsHelper['To Year']['$y']);
        formData.append('CGPA', dataCandidateDetalsHelper['CGPA/Percentage']);
        formData.append('userId', userData.userId);
        ApiService.postAdopter('userEducationUpdate', formData).then((res) => {
            if (res) {
                handleDrawerClose()
                getUserData()
                if (res.status == 200) {
                    setLoading(false)
                    dispatch(setSnackBar({ dataLoad: true, message: "Data added successfully", severity: "success" }))
                }
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            setLoading(false)
        })

    }

    useEffect(() => {
        console.log("Shweta needs this", { userEducationData });
        if (userEducationData) {
            educationDetailsHelper.set({
                'University/Board Name': userEducationData.universityName,
                'Degree': userEducationData.degree,
                'Field of study': userEducationData.fieldOfStudy,
                'CGPA/Percentage': userEducationData.CGPA,
                'From Year': userEducationData.fromYear,
                'To Year': userEducationData.toYear,

            }, () => {
                // personalDetailsHelper.model = updatedModel;
            })
        }
    }, [userEducationData])
    return (<>
        <Stack >
            <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={5.5}>
                        <Box  display='flex'>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Add Education Details</Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={6.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" size="small" sx={{ mx: 1 }} onClick={handleDrawerClose}><Typography variant="body2">CANCEL</Typography></Button>
                        <LoadingButton
                            color="primary"
                            loading={loading}
                            size="small"
                            loadingPosition="start"
                            startIcon={<Save />}
                            variant="contained"
                            // disabled={disableSumbit}
                            onClick={addContactDetail}
                        >
                            <span>SAVE</span>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
        <Box >
            <Box sx={{ width: 1 }}>
                <Box component="form"  sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => { console.log("Hind Ki Chadar", { data }); setCandidateDetailsHelperData(data) }} updatedData={candidateDetailsHelperData} {...educationDetailsHelper.model} />
                </Box>

            </Box>
        </Box>
    </>)
}

export default AddEducationDrawer;