import React, { useState, useRef, useEffect  } from 'react';
import TextField from '@mui/material/TextField';
import { publish } from '../helper/publisher.js';
import { Avatar, Box, Link, Stack, Typography, Alert } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import WordFile from "../../assets/images/word.png";
import PDFFile from "../../assets/images/pdf.png";
import ExcelFile from "../../assets/images/excel.png";
import ImageFile from "../../assets/images/picUpload.png";
import {LinearProgress, Grid, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import Box from '@mui/material/Box';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2563EB',

  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000000',
    fontWeight: '400',
    boxShadow: '-1px 0px 6px #aaa',
    // padding: "15px",
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #2563EB',
    borderRadius: 2,
  },
}));


const InputUploadFiles = ({ value, label,showErrors, model,chainUpdatedData, modelKey,other = {}, validation, id, type, sx, xs, multiline, rows,editMode, version, change = () => { }, ...props }) => {

  const [textValue, setTextValue] = useState(value);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const fileSize = useSelector((state) => (state.filesValidation?.fileSize));
  const fileExtensions = useSelector((state) => (state.filesValidation?.fileExtensions));
  const [fileExtension, setFileExtension] = useState(other.fileExtension ?? fileExtensions);
  const fileAllowedMessage = other.fileAllowedMessage ?? 'PNG, JPG, XLS, DOC, PDF and PPT';
  const [imagePreviews, setImagePreviews] = useState([]);
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    // console.log("Ankur Ka Data",{showErrors, textValue, cond: !textValue || textValue.length < 0 })
    if(showErrors){
      var error = false;
      var errorMessage = false;
      if(!textValue || textValue.length < 0){
        setError(error);
        setErrorMessage("Field is required");
      }
    }
  }, [showErrors]);

  //This function enables the functionality of showing the error once the textField is clicked by checking if the field the empty and if the field contains "required" as validator or not
  // const onClickTextValue = () => {
  //   if ((textValue === "") && (model[modelKey].validators[0].name === "required")) {
  //     setError(true)
  //     setErrorMessage("Field is required")
  //   }
  // }

  // const onChangeTextValue = (e) => {
  //   setTextValue(e.target.value);
  //   model[modelKey].value = e.target.value;
  //   // var error = false;
  //   // var errorMessage = false;

  //   // for (let i = 0; i < validation?.length; i++) {
  //   //   var res = validation[i](e.target.value);
  //   //   if (res.error) {
  //   //     error = res.error;
  //   //     errorMessage = res.errorMessage;
  //   //     break;
  //   //   }
  //   // }

  //   // setError(error);
  //   // setErrorMessage(errorMessage);
  //   change(e);

  //   /*PUBLISHING DATA TO THE TOUCHED HELPER */
  //   publish({ data: model, id: 'touched' + id });

  //   /*PUBLISHING DATA TO THE ACTIVETOUCH HELPER */
  //   publish({ data: model[modelKey], id: 'activeTouch' + id });

  //   /*PUBLISHING DATA TO THE FILLED HELPER */
  //   publish({ data: error, id: 'filled' + id });
  // }

  const regex = /^(?!.*\.{2,}|.*\.+\.+|.*\x00|.*[\/\|\*\?\<\>\:\;\[\]\{\}])/;

  // If passed returns true
  function checkFilename(filename) {
    return (regex).test(filename);
  }

  const selectFiles = (event) => {

    const file = event.target.files[0];

    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
      const currentFileExtension = '.' + file.name.split('.').pop();
      if (fileExtension.includes(currentFileExtension.toLowerCase()) && fileSizeInMB <= fileSize && checkFilename(file.name)) {
        const imageUrl = URL.createObjectURL(file);
        console.log({file, value: event.target.value})
        setFileName(file.name);
        setTextValue([file]);
        // model[modelKey].value = event.target.value;
        model[modelKey].value = [file];
        setImagePreviews([imageUrl]);
        // setProgressInfos({ val: [] });
        // setMessage([]);
        // setInputKey(inputKey + 1);
        // setfileUploadData([file]);
        
        // upload(0, file);
        setErrorMessage(false);

        // change(event.target.value);
        change([file]);

        /*PUBLISHING DATA TO THE TOUCHED HELPER */
        publish({ data: model, id: 'touched' + id });

        /*PUBLISHING DATA TO THE ACTIVETOUCH HELPER */
        publish({ data: model[modelKey], id: 'activeTouch' + id });

        /*PUBLISHING DATA TO THE FILLED HELPER */
        publish({ data: error, id: 'filled' + id });

      } else {
        event.target.value = null; // Clear the input
        setErrorMessage(fileSizeInMB <= fileSize ? 'File(s) not supported. Upload a supported format.' : ('File size exceeds the limit (' + fileSize + 'MB).'))
      }
    }
  };

  const handleRemove = () => {
    setFileName('');
    setTextValue([]);
    model[modelKey].value = [];
    setImagePreviews([]);
    setErrorMessage(false);
    change([]);
    fileInputRef.current.value = '';
  }

  function formatBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    console.log({bytes, i})
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  }

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
  
  
    
    const files = event.dataTransfer.files;
  
    // Validate files and call selectFiles function
    selectFiles({...event, target: {...event.target,files: files}});
  };

  return (

    <Box sx={isMobile?xs:sx} >
      <label htmlFor='fileUploadMyAccount' style={{width:'100%'}}>
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: "0.5rem",
            border: "2px dashed rgba(0, 0, 0, 0.12)",
            borderRadius: "12px",
            backgroundColor: "rgba(201, 201, 201, 0.1)",
            ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
            <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
              <UploadFile color="primary" />
            </Avatar>
          </Box>
          <Box className="upload-btn-wrapper">
            <span>
            
              <Link className="btn" sx={{ textDecoration: "underline" }}>
                Click to upload
              </Link>
              <input
                ref={fileInputRef}
                id="fileUploadMyAccount"
                type="file"
                name="myfile"
                multiple
                accept={fileExtension?.join(',')}
                readOnly={editMode != true}
                onChange={selectFiles}
              />
            </span>
            <span> or drag and drop </span>
          </Box>
          <Box m='1rem 0rem 0rem'>
            <Typography variant="body2" color="text.secondary" display="block" gutterBottom> {fileAllowedMessage} (max. {fileSize}MB)</Typography>
          </Box>
          <Box m='1rem 0rem'>
       
          {errorMessage ? <Alert variant="none" sx={{ color: "red" }} severity="error">
                        {errorMessage}
                      </Alert> : ""}
        </Box>
        </Stack>
      </label>
      <Box>
        
        <Box sx={{ ml: '3rem' }}>
              {fileName && (
                  <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2} md={2}>
                        <Box>
                          {(textValue?.[0]?.name?.endsWith(".docx")) ?
                            (
                              <>
                                <img
                                  className="preview"
                                  src={WordFile}
                                  alt={"image"}
                                />
                              </>
                            )
                            : textValue?.[0]?.name?.endsWith(".pdf") ? (
                              <img
                                className="preview"
                                src={PDFFile}
                                alt={"image"}
                              />
                            ) : textValue?.[0]?.name?.endsWith(".xlsx") ||
                              textValue?.[0]?.name?.endsWith(".xls") ||
                              textValue?.[0]?.name?.endsWith(".csv") ? (
                              <img
                                className="preview"
                                src={ExcelFile}
                                alt={"image"}
                              />
                            ) : (
                              <img height={30} width={30} className="preview" src={ImageFile} alt={"image"} />
                            )
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <Box>
                          <Box>
                            <HtmlTooltip title={fileName}>
                              <span>
                                {fileName.length > 28
                                  ? fileName.substring(0, 28) + '...'
                                  : fileName}
                              </span>
                            </HtmlTooltip>
                          </Box>
                          <Box sx={{ my: 1 }}>
                            <Stack direction="row" spacing={2}>
                              <span><li>{formatBytes(textValue?.[0]?.size)}</li></span>

                              {/* <span>
                                {message.length > 0 && (
                                  <Box className="alert alert-secondary" role="alert">
                                    <li>
                                      {message[index]}
                                    </li>
                                  </Box>
                                )}
                              </span> */}
                            </Stack>
                          </Box>
                          <Box>
                            {/* <LinearProgress value={progressInfo.percentage} /> */}
                            <LinearProgress variant="determinate" value={100} sx={{ '& .MuiLinearProgress-bar': { backgroundColor: 'blue' } }} />
                            {/* <LinearProgress variant="determinate" value={100} /> */}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <Box sx={{ mb: 1 }}>
                          <IconButton aria-label="upload picture" component="label" onClick={handleRemove}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
              )}
               

            </Box>
      </Box>
    </Box>

  )
}

export default InputUploadFiles