import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import { Box, Grid, Typography, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import BasicInformationData from '../../datafiles/AdminDashboard/basicInformationDummyData.json'
import RenderHelper from "../../form/ReactiveForm";
import FormHelper from "../../form/FormHelper";
import ApiService from "../../utils/ApiService";
import { setSnackBar } from "../../features/snakebar/snakebar";
import { setUserInfo } from "../../features/userInfoStore/userSlice";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";

let tempBI = BasicInformationData
localStorage.setItem("tempBI", JSON.stringify(tempBI))
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
//Component code starts here
const AddAddressDrawer = ({ id, parentId, drawerHeading, cities = [], countries = [], states = [], setOpenAddress }) => {
    const userData = useSelector((state) => state?.user?.userListName);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const handleDrawerClose = () => {
        setOpenAddress(false)
    };

    let inputProps = {};
    let addressTypes = [{ value: 'Present', label: 'Present' }, { value: 'Permanent', label: 'Permanent' }];
    console.debug("a1", userData)
    if (userData.userAddress && userData.userAddress.length > 0) {
        const permanentIndex = addressTypes.findIndex(type => type.value === 'Permanent');
        const presentIndex = addressTypes.findIndex(type => type.value === 'Present');
        if (userData.userAddress[0].addressType === 'Present') {
            if (presentIndex !== -1) {
                addressTypes[presentIndex].disable = true;
            }
        }
        if (userData.userAddress[0].addressType === 'Permanent') {
            if (permanentIndex !== -1) {
                addressTypes[permanentIndex].disable = true;
            }
        }

        // Find the 'Permanent' object in addressTypes array and add the disable key



    }

    console.debug("AddressDrawer:userData:addressTypes", addressTypes)

    let mobileDetailsModel = [
        { label: 'Address Type', type: "singleSelect", value: '',sx: { gridColumn: 'span 3 ', m: 1 }, xs: { gridColumn: 'span 6' }, validators: [{ name: 'required' }], data: addressTypes },
        { label: 'Location', type: "textField", value: '', sx: { gridColumn: 'span 3 ', m: 1 }, xs: { gridColumn: 'span 6' }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[a-zA-Z0-9\s\-_',.()&]*$/, errorMessage: "Field must only contain letters, numbers, spaces, -, ', ,, ., (, ), &." }] },
        // { label: 'Location', type: "textField", value: '', sx: { gridColumn: 'span 6', m: 1 },validators: [{ name: 'required' },{ name: 'required', regex: /^[a-zA-Z0-9\s\-_',.()&]*$/, errorMessage: "Field must only contain letters, numbers, spaces, -, ', ,, ., (, ), &." }]},
        { inputProps: inputProps, label: 'City', type: "singleSelect", value: '', sx: { gridColumn: 'span 3 ', m: 1 }, xs: { gridColumn: 'span 6' }, validators: [{ name: 'required' }], data: cities },
        { inputProps: inputProps, label: 'State', type: "singleSelect", value: '', sx: { gridColumn: 'span 3 ', m: 1 }, xs: { gridColumn: 'span 6' }, validators: [{ name: 'required' }], data: states },
        { inputProps: inputProps, label: 'Country', type: "singleSelect", value: '', sx: { gridColumn: 'span 3 ', m: 1 }, xs: { gridColumn: 'span 6' }, validators: [{ name: 'required' }], data: countries },
        { label: 'Pincode', type: "textField", value: '', sx: { gridColumn: 'span 3', m: 1 }, xs: { gridColumn: 'span 6' }, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[0-9]+$/, errorMessage: 'Field must only contain numbers' }] },
    ];
    const candidateDetailsModel = new FormHelper({ model: mobileDetailsModel, id: 'contactDetails' });
    const [candidateDetailsHelperData, setCandidateDetailsHelperData] = useState(mobileDetailsModel);
    const [showErrors, setShowErrors] = useState(0);
    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
    }
    const addContactDetail = () => {
        let dataCandidateDetalsHelper = candidateDetailsHelperData.reduce((prev, curr) => {
            prev[curr.label] = curr.value;
            return prev;
        }, []);
        var error = false;
        var errorMessage = false;
        for (let i = 0; i < candidateDetailsHelperData.length; i++) {
            for (let j = 0; j < candidateDetailsHelperData[i]?.validation?.length; j++) {
                var res = candidateDetailsHelperData[i]?.validation[j](candidateDetailsHelperData[i].value);
                if (res.error) {
                    error = res.error;
                    errorMessage = res.errorMessage;
                    break;
                }
            }
        }
        if (error) {
            setShowErrors(showErrors + 1);
            return;
        }
        let formData = new FormData();
        formData.append('addressType', dataCandidateDetalsHelper['Address Type']);
        // formData.append('location', dataCandidateDetalsHelper['Location']);
        formData.append('address', dataCandidateDetalsHelper['Location']);
        formData.append('city', dataCandidateDetalsHelper['City']);
        formData.append('pincode', dataCandidateDetalsHelper['Pincode']);
        formData.append('state', dataCandidateDetalsHelper['State']);
        formData.append('country', dataCandidateDetalsHelper['Country']);
        // formData.append('location', dataCandidateDetalsHelper['Location']);
        formData.append('userId', userData.userId);
        console.log({ dataCandidateDetalsHelper })
        setLoading(true)
        ApiService.postAdopter('userAddressUpdate', formData).then((res) => {
            console.log({ res })
            if (res) {
                handleDrawerClose()
                getUserData()
                if (res.status == 200) {
                    dispatch(setSnackBar({ dataLoad: true, message: "Data added successfully", severity: "success" }))
                    setLoading(false)
                }
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
            setLoading(false)
        })
    }
    return (<>
        <Stack  >
            <Box sx={{ p: 'var(--padding-top-accordian-header)' }}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={5}>
                        <Box display='flex'>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Add Address Details </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" size="small" sx={{ mx: 1 }} onClick={handleDrawerClose}><Typography variant="body2">CANCEL</Typography></Button>

                        <LoadingButton
                            color="primary"
                            loading={loading}
                            size="small"
                            loadingPosition="start"
                            startIcon={<Save />}
                            variant="contained"
                            // disabled={disableSumbit}
                            onClick={addContactDetail}
                        >
                            <span>SAVE</span>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
        <Box >
            <Box sx={{ width: 1 }}>
                <Box component="form" p={2} sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => { console.log("Hind Ki Chadar", { data }); setCandidateDetailsHelperData(data) }} {...candidateDetailsModel.model} />
                </Box>
            </Box>
        </Box>
    </>)
}
export default AddAddressDrawer;