import * as React from 'react';
import { useState } from 'react';
import './Header.css';
import Logo from '../../assets/images/image4.png';
import AnimatedCoin from '../../assets/images/animatedCoin.gif';
// import profileImageIcon from '../../assets/images/Avtaar.png';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Settings from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Profile from '../../assets/images/profile.png';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationData from '../../datafiles/dashboard/notificationMenuData.json'
import { ImportantDevices } from '@mui/icons-material';
import SharedDrawer from '../../features/drawers/Drawer';
import SettingDrawer from '../../components/drawerBody/SettingDrawer'
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer, closeDrawer } from '../../features/drawers/drawerSlice';
import { Link } from 'react-router-dom';
import { setOpen, setImage } from "../../features/uploadPic/uploadSlice";
import { setProfileImage } from '../../features/commonDataSlice/commonSlice';
import { setCurrentRoute } from '../../features/sidebarRouting/sidebarSelectionSlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid } from '@mui/material';
import ApiService from '../../utils/ApiService';
import { setUserInfo } from '../../features/userInfoStore/userSlice';
import { setFileExtensions, setFileSize } from '../../features/filesValidation/filesValidation';
import TransitionsSnackbar from '../../features/snakebar/Snackbar';
import { setSnackBar } from '../../features/snakebar/snakebar';
import { setTools, setSkills } from '../../features/toolsList/toolsList';
import Skeleton from '@mui/material/Skeleton';

// const dispatch = useDispatch();
// const options = [
//   'None',
//   'Atria',
//   'Callisto',
//   'Dione',
//   'Ganymede',
//   'Hangouts Call',
//   'Luna',
//   'Oberon',
//   'Phobos',
//   'Pyxis',
//   'Sedna',
//   'Titania',
//   'Triton',
//   'Umbriel',
// ];

const ITEM_HEIGHT = 48;

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '3rem',
    border: '1px solid #EEF6FF',
    backgroundColor: '#FFFFFF;',
    '&:hover': {
        backgroundColor: '#FFFFFF;',
    },
    marginRight: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

// const logoSize = styled('div')(({ theme }) => ({

//     [theme.breakpoints.up('sm')]: {
//         scale: '.8',
//     },
// }));


const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),

        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100ch',
        },
    },
}));

//list view///
// function generate(element) {
//     return [0, 1].map((value) =>
//       React.cloneElement(element, {
//         key: value,
//       }),
//     );
//   }

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));
// const ChangeProfile = () => {

// };
///

//HOOK FOR 3 DOT MENU

export default function PrimarySearchAppBar() {
    const { image } = useSelector(state => state.profile);
    const userData = useSelector(state => state?.user?.userListName);
    console.log('a1',userData)
    const [companyData, setCompanyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [anchorE3, setAnchorE3] = React.useState(null);
    const open2 = Boolean(anchorE3);
    const handleClick2 = (event) => {
        setAnchorE3(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorE3(null);
    };

    const dispatch = useDispatch();
    const componentDrawer = (id) => {
        console.log("Faltu")
        dispatch(openDrawer(id));
    }
    const cancel = (id) => {
        dispatch(closeDrawer(id));
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    // const handleMobileMenuOpen = (event) => {
    //     setMobileMoreAnchorEl(event.currentTarget);
    // };
    /////
    // const [anchorE2, setAnchorE2] = React.useState(null);

    const [anchorE2, setAnchorE2] = React.useState(null);
    const open = Boolean(anchorE2);
    const handleClick1 = (event) => {
        setAnchorE2(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorE2(null);
    };

    ////

    //list view//
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);
    /////

    React.useEffect(() => {
        ApiService.get('userInfo', {toolsUser: 'null'}).then(res => {
            let response = res.data.data
            if (response) {
                console.debug("headet useer api call", response)
                // setUserList(response)
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

        ApiService.get('comapnyLog').then(res => {
            let response = res.data.data
            if (response) {
                setCompanyData(response)
                setIsLoading(true)
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

        ApiService.get('allowedFileExtensions').then(res => {
            let response = res.data.data
            if (response) {
                console.debug("headet useer api call", response)
                // setUserList(response)
                dispatch(setFileExtensions(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

        ApiService.get('uploadFileSize').then(res => {
            let response = res.data.data
            if (response) {
                console.debug("headet useer api call", response)
                // setUserList(response)
                dispatch(setFileSize(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

        ApiService.get('tools').then(res => {
            let response = res.data.data
            if (response) {
                console.debug("headet useer api call tool", response)
                // setTools(response)
                dispatch(setTools(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

        ApiService.get('skills').then(res => {
            let response = res.data.data
            if (response) {
                console.debug("headet useer api call tool", response)
                // setTools(response)
                dispatch(setSkills(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

    }, [])

    const selectProfileSidebar = (e) => {
        handleMenuClose(e);
        dispatch(setCurrentRoute('/'));
    } 

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <>
            <Menu
                className='menu-btn-style'
                anchorEl={anchorEl}
                id="account-menu"
                open={isMenuOpen}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        // width: '15rem',
                        // '&:before': {
                        //     content: '""',
                        //     display: 'block',
                        //     position: 'absolute',
                        //     top: 0,
                        //     right: 37.5,
                        //     width: 15,
                        //     height: 15,
                        //     bgcolor: 'background.paper',
                        //     transform: 'translateY(-50%) rotate(45deg)',
                        //     zIndex: 0,
                        // }
                    },
                }}
            >
                <MenuItem>
                    <Typography px={2} pt={1} variant="body2" color="text.primary">Welcome {userData?.userName + " " + (userData?.lastName ?? '')},</Typography>
                </MenuItem>
                
                {/* <MenuItem sx={{ display: { xs: 'flex', sm: 'none' } }}>
                    <IconButton size="small" color="inherit">
                        <Badge color="error">
                            <span className='coinSize'><img src={AnimatedCoin} alt="React Logo" /></span>
                            <span className='numericSize'>20</span>
                        </Badge>
                    </IconButton>
                </MenuItem> */}
                {/* <Divider /> */}
                {/* <MenuItem onClick={(e) => { console.log(e); componentDrawer('setting'); dispatch(setCurrentRoute('/setting')) }}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem> */}
                <Link to="/" className='disable-link-styles'>
                    <MenuItem onClick={selectProfileSidebar}>
                        <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        Profile
                    </MenuItem>
                </Link>
                <Divider sx={{ my: '.5rem' }} />
                <Link to="/logout" style={{ textDecoration: 'none' }}>
                    <MenuItem>
                        <Typography variant="body2" color="text.primary">LOG OUT</Typography>

                    </MenuItem>

                </Link>
            </Menu>
        </>
    ) ;
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem>
                <IconButton size="small" color="inherit">
                    <Badge color="secondary">
                        <span className='coinSize'><img src={AnimatedCoin} alt="React Logo" /></span>
                        <span className='numericSize'>20</span>
                    </Badge>
                </IconButton>
                <p>Coins</p>
            </MenuItem> */}
            {/* <MenuItem>
                <IconButton
                    size="large"
                    color="inherit"
                >
                    <Badge color="error">
                        <NotificationsActiveIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    {/* <Avatar sx={{ width: 26, height: 26 }}>
                        <span><img src={Profile} alt="Profile Logo" /></span>
                    </Avatar> */}
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar className='app-style'>
                <Toolbar>
                    <Grid container spacing={2} >
                        <Grid item xs={2}>
                            <Box className='logo'>
                            {isLoading ?
                                <img className='logoSize' src={companyData.company_logo}
                                    onError={(e) => {
                                        setIsLoading(false)
                                    }}
                                    alt="React Logo" />  :
                                    <Skeleton variant="circular" width={40} height={40} sx={{marginTop:'5px'}} />
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {/* <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon sx={{ color: '#64748B' }} />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </Search>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                                <Search className='search-style'>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                </Search>
                            </Box> */}
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: { xs: 'none', sm: 'flex', justifyContent: 'flex-end' } }}>
                                {/* <Link className="disable-link-styles" to="/leaderboard">
                                <IconButton size="small" color="inherit">
                                    <Badge color="error">
                                        <Box display='inline-flex' sx={{alignItems:'center'}}>
                                            <img className='coinSize' src={AnimatedCoin} alt="React Logo" />
                                            <Typography variant="h6">20</Typography>
                                        </Box>
                                    </Badge>
                                </IconButton>
                                </Link> */}
                                {/* <IconButton
                                    size="large"
                                    color="inherit"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick1}>
                                    <Badge color="error">
                                        <NotificationsActiveIcon />
                                    </Badge>
                                </IconButton> */}
                                <Menu
                                    // className='menu-size'
                                    id="basic-menu"
                                    sx={{ top: '-20px', left: '-80px' }}
                                    anchorEl={anchorE2}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {/*3 dot menu is defined here*/}
                                    <Box>
                                        <Box>
                                            <ListItem secondaryAction={
                                                <Box>
                                                    <IconButton
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={open ? 'long-menu' : undefined}
                                                        aria-expanded={open ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleClick2}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="long-menu"
                                                        sx={{ left: '-100px' }}
                                                        anchorEl={anchorE3}
                                                        open={open2}
                                                        onClose={handleClose2}
                                                        PaperProps={{
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                width: '20ch',
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem> Mark all as read</MenuItem>
                                                    </Menu>
                                                </Box>}>
                                                <ListItemText primary={<Typography variant='h6'>Notifications (7)</Typography>} secondary={<Typography variant="body2">You have 2 unread messages</Typography>} />
                                            </ListItem>
                                        </Box>
                                        <Box className='table-height' style={{ height: '20rem', overflow: 'auto', }}>
                                            {NotificationData.map((key, index) => {
                                                return (
                                                    <List dense={dense} key={index} sx={{ padding: '0rem', cursor: 'pointer' }} >
                                                        <ListItem sx={{ ':hover': { bgcolor: 'var(--color-lightblue)', }, }} secondaryAction={<ListItemText edge="end" primary={<Typography variant="caption" color='var(--color-dashboard-tasklist)' fontWeight='var(--font-weight-5)'>5m ago</Typography>} />}>
                                                            <ListItemAvatar>
                                                                <IconButton>
                                                                    <img src={key.icon} alt="React Logo" />
                                                                </IconButton>
                                                            </ListItemAvatar>
                                                            <ListItemText sx={{ pr: 'var(--padding-tasklist-element5)' }} primary={<Typography variant="subtitle2">{key.primary}</Typography>} secondary={<Typography variant="body2">{key.secondary}</Typography>} />
                                                        </ListItem>
                                                        <Divider />
                                                    </List>
                                                )
                                            })}
                                        </Box>

                                    </Box>
                                </Menu>
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    {/* <Avatar sx={{ width: 35, height: 35 }}>
                                        <span><img src={image} alt="Profile Logo" /></span>
                                    </Avatar> */}
                                    {/* <Avatar id="profile-icon" alt="Travis Howard" src={profileImageIcon} sx={{ width: 35, height: 35 }} /> */}
                                    {userData?.userAvatar && userData?.userAvatar != "https://gatewayapi.eela.tech/media/?file=default.png" ?
                                        <Avatar id="profile-icon" alt="user-avatar" src={userData?.userAvatar} sx={{ width: 35, height: 35 }} />
                                        :
                                        <AccountCircleIcon />}
                                </IconButton>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', sm: 'none', justifyContent: 'flex-end' } }}>
                                <IconButton
                                    size="large"
                                    color="inherit"
                                >
                                    <Badge color="error">
                                        <NotificationsActiveIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <Avatar sx={{ width: 26, height: 26 }}>
                                        <span><img src={Profile} alt="Profile Logo" /></span>
                                    </Avatar>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* <Box sx={{ flexGrow: 1 }} /> */}
                    {/* <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon sx={{color:'#64748B'}} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    </Box> */}
                    {/* <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                        <Search className='search-style'>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                        </Search>
                    </Box> */}
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <SharedDrawer id='setting'>
                <Box className="tasklist_drawer">
                    <SettingDrawer parentId='setting' />
                </Box>
            </SharedDrawer>
            <TransitionsSnackbar />
        </Box>
    );
}