import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Stack, CardHeader, List, ListItem, ListItemButton, ListItemText, Skeleton, Grid, IconButton, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import { Add, Call, Delete, Edit, Email, ForkRight, LocationOn, } from '@mui/icons-material';
import SharedDrawer from '../../features/drawers/Drawer';
import { openDrawer } from '../../features/drawers/drawerSlice';
import AddContactDrawer from '../../components/AdminDrawers/AddContactDrawer';
import AddEmailDrawer from '../../components/AdminDrawers/AddEmailDrawer';
import SharedDialog from '../../features/dialogBox/dialogBox';
import { openDialog, closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ApiService from '../../utils/ApiService';
import { setSnackBar } from '../../features/snakebar/snakebar';
import { setUserInfo } from '../../features/userInfoStore/userSlice';
import AddAddressDrawer from '../../components/AdminDrawers/AddAddressDrawer';
import RenderHelper from '../../form/ReactiveForm';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ContactDetail = ({ }) => {
    const dispatch = useDispatch();
    const userAddressDetails = useSelector((state) => state?.user?.userListName?.userAddress);
    console.log('a1', userAddressDetails)
    const userContactDetails = useSelector((state) => state?.user?.userListName?.userContact);
    const userEmailDetails = useSelector((state) => state?.user?.userListName?.userEmail);
    const [hoveredItems, setHoveredItems] = useState(Array(userContactDetails?.length).fill(false));
    const [hoveredEmailItems, setHoveredEmailItems] = useState(Array(userEmailDetails?.length).fill(false));
    const [curerentItemToDelete, setCurerentItemToDelete] = useState({});
    const [currentEditEmailIndex, setCurrentEditEmailIndex] = useState();
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [openAddress, setOpenAddress] = useState(false);
    const [openContact, setOpenContact] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    // const [accordionOpen, setAccordionOpen] = useState({
    //     panel1: false,
    //     panel2: false,
    //     panel3: false,
    //     // Add more panels as needed
    // });
    const [expandedAccordion, setExpandedAccordion] = useState(null);
    const openAddressTextfield = () => {
        setOpenAddress(!openAddress)
    }
    const openEmailTextfield = () => {
        setOpenEmail(!openEmail)
    }
    const openContactTextfield = () => {
        setOpenContact(!openContact)
    }
    const openMobileDrawer = () => {
        dispatch(openDrawer('mobile'));
    }
    const openEmailDrawer = () => {
        setCurrentEditEmailIndex(-1);
        dispatch(openDrawer('email'));
    }
    const openEditEmailDrawer = (data, index) => {
        dispatch(openDrawer({ id: 'email', data }));
        setCurrentEditEmailIndex(index);
    }
    const handleListItemMouseLeave = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = false;
        setHoveredItems(newHoveredItems);
    };
    const handleListItemMouseEnter = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = true;
        setHoveredItems(newHoveredItems);
    };
    const handleEmailListItemMouseLeave = (index) => {
        const newHoveredEmailItems = [...hoveredEmailItems];
        newHoveredEmailItems[index] = false;
        setHoveredEmailItems(newHoveredEmailItems);
    };
    const handleEmailListItemMouseEnter = (index) => {
        const newHoveredEmailItems = [...hoveredEmailItems];
        newHoveredEmailItems[index] = true;
        setHoveredEmailItems(newHoveredEmailItems);
    };

    const openDeleteDialog = (type, id, index) => {
        setCurerentItemToDelete({ type, id, index });
        dispatch(openDialog('deleteDialog'));
    }
    function getUserData() {
        ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
            let response = res.data.data
            if (response) {
                dispatch(setUserInfo(response));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

    }
    const deleteHandler = () => {
        if (!curerentItemToDelete.type) {
            return;
        }
        switch (curerentItemToDelete.type) {
            case 'userContactDetails':
                ApiService.delete('userContact', {}, curerentItemToDelete.id).then((res) => {
                    console.log({ res })
                }).catch((error) => {
                    console.log(error);
                    dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
                })
                dispatch(closeDialog('deleteDialog'));
                break;
            case 'userEmailDetails':
                ApiService.delete('userEmail', {}, curerentItemToDelete.id).then((res) => {
                    console.log({ res })
                    getUserData()
                }).catch((error) => {
                    console.log(error);
                    dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
                })
                dispatch(closeDialog('deleteDialog'));
                break;
        }
    }
    useEffect(function () {
        // setValues();
        ApiService.get('city').then((res) => {
            let data = res.data.data;
            if (data) {
                setCities(data.map(v => {
                    return {
                        // value: v.cityId,
                        value: v.city,
                        label: v.city
                    }
                }))
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))

        })

        ApiService.get('state').then((res) => {
            let data = res.data.data;
            if (data) {
                console.debug("stateData", data)
                setStates(data.map(v => {
                    return {
                        // value: v.stateId,
                        value: v.state,
                        label: v.state != null ? v.state : ""
                    }
                }))
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })

        ApiService.get('country').then((res) => {
            let data = res.data.data;
            if (data) {
                setCountries(data.map(v => {
                    return {
                        // value: v.countryId,
                        value: v.country,
                        label: v.country
                    }
                }))
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setSnackBar({ dataLoad: true, message: error.message, severity: "error" }))
        })
       
        window.scrollTo(0, 0);

    }, [])

    // const handleAccordionChange = (panel) => (event, isExpanded) => {
    //     setAccordionOpen((prevState) => ({
    //         ...prevState,
    //         [panel]: isExpanded ? true : false, // Set the clicked panel to be open, close others
    //     }));
    // };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };

    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Contact Details</Typography>
                    </Box>
                </Stack>
                {/* Contact Detail Card */}
                <Box>
                    <Card sx={openContact ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                        <Accordion expanded={expandedAccordion === 'panel1'} onChange={handleAccordionChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <CardHeader
                                    sx={{ pl: 1 }}
                                    avatar={<Call color={expandedAccordion === 'panel1' ? 'primary' : ""} />}
                                    title={<Typography variant='body1' fontWeight="var(--font-weight-5)" >My Mobile Numbers</Typography>}
                                    subheader="Please fill your mobile number details"
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* <CardHeader
                            title={<Typography variant='h6' >My Mobile Numbers</Typography>}
                        /> */}
                                {userContactDetails ? userContactDetails.map((data, index) => (
                                    <Box sx={{ p: 2, py: 1 }}>
                                        <Typography variant="subtitle1" color="text.primary" sx={{ pb: 2 }}>Number {index + 1}</Typography>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={6} sx={{ mb: 3 }}>
                                                <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Number Type</Typography>
                                                <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.contactType}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ mb: 3 }}>
                                                <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Mobile Number</Typography>
                                                <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.contactNo}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Box>
                                )) :
                                    [1, 2].map(d => <ListItem><Skeleton variant="text" sx={{ width: "100%", height: 40 }} /></ListItem>)
                                }
                                {openContact ?
                                    <AddContactDrawer parentId='mobile' drawerHeading={"Add Mobile Number"} setOpenContact={setOpenContact} />
                                    :
                                    <Box textAlign={"center"}>
                                        <Button startIcon={<Add />} onClick={openContactTextfield} variant="outlined"> ADD MOBILE NUMBER</Button>
                                    </Box>
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Box>


                {/* Email Detail Card */}
                <Box>
                    <Card sx={openEmail ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                        <Accordion expanded={expandedAccordion === 'panel2'} onChange={handleAccordionChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <CardHeader
                                    sx={{ pl: 1 }}
                                    avatar={<Email color={expandedAccordion === 'panel2' ? 'primary' : ""} />}
                                    title={<Typography variant='body1' fontWeight="var(--font-weight-5)">My Email Addresses</Typography>}
                                    subheader="Please fill your email details"
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                {userEmailDetails ? userEmailDetails.map((data, index) => (
                                    <Box sx={{ px: 2, py: 1 }}>
                                        <Typography variant="subtitle1" color="text.primary" sx={{ pb: 2 }}>Email Address {index + 1}</Typography>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={4} sx={{ mb: 3 }}>
                                                <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Email Type</Typography>
                                                <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.emailType}</Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ mb: 3 }}>
                                                <Typography variant='body2' sx={{ pb: 1, color: "#757575" }} >Email Address</Typography>
                                                <Typography variant='body1' sx={{ fontWeight: "var(--font-weight-4)" }}>{data.email}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Box>
                                )) : [1, 2].map(d => (
                                    <ListItem key={d}>
                                        <Skeleton variant="text" sx={{ width: "100%", height: 40 }} />
                                    </ListItem>
                                ))}
                                {openEmail ?
                                    <AddEmailDrawer parentId='email' currentEditEmailIndex={currentEditEmailIndex} setOpenEmail={setOpenEmail} />
                                    :
                                    <Box textAlign={"center"} >
                                        <Button startIcon={<Add />} variant="outlined" onClick={openEmailTextfield}> ADD EMAIL</Button>
                                    </Box>
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                    {/* ATBBYvVj6pbk9btmFeqwRxXLExJm8EBB51B1 */}
                    {/*  -------- Delete Dialog Box Code  */}
                    <SharedDialog id="deleteDialog">
                        <Box pb="1rem">
                            <DialogTitle id="alert-dialog-title">
                                {"Delete"}
                            </DialogTitle>
                            <DialogContent sx={{ padding: '16px 24px' }}>
                                <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                                    This will be deleted forever and you won't be able to restore it.
                                    {/* {methodsAndState.dynamicDeleteMessage} */}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ padding: '16px 24px' }}>
                                <Button onClick={() => dispatch(closeDialog('deleteDialog'))} >
                                    CANCEL
                                </Button>
                                <Button onClick={deleteHandler} color='primary' variant="contained" >YES, DELETE</Button>
                            </DialogActions>
                        </Box>
                    </SharedDialog>
                </Box>


                {/* Address Details Card */}
                <Box>
                    <Card sx={openAddress ? { mb: 3, pb: 0, borderLeft: "4px solid rgb(60, 130, 245) " } : { mb: 3, pb: 0 }}>
                        <Accordion expanded={expandedAccordion === 'panel3'} onChange={handleAccordionChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"  >
                                <CardHeader
                                    sx={{ pl: 1 }}
                                    avatar={<LocationOn color={expandedAccordion === 'panel3' ? 'primary' : ''} />}
                                    title={<Typography variant='body1' fontWeight="var(--font-weight-5)" >My Addresses</Typography>}
                                    subheader="Please fill your address details"
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                {userAddressDetails ? userAddressDetails.map((data, index) => (
                                    <Box sx={{ p: 2 }}>
                                        {console.debug("Address Type", data)}
                                        <Typography variant="subtitle1" color="text.primary" sx={{ pb: 2 }}>{data.addressType}</Typography>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={6} md={4} sx={{ mb: 5 }}>
                                                <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Country/region</Typography>
                                                <Typography variant='body1' fontWeight="var(--font-weight-4)">{data.country}</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={4} sx={{ mb: 5 }}>
                                                <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>State</Typography>
                                                <Typography variant='body1' fontWeight="var(--font-weight-4)">{data.state}</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={4} sx={{ mb: 5 }}>
                                                <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>City</Typography>
                                                <Typography variant='body1' fontWeight="var(--font-weight-4)">{data.city}</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={4} sx={{ mb: 5 }}>
                                                <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Pincode</Typography>
                                                <Typography variant='body1' fontWeight="var(--font-weight-4)">{data.pincode}</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={4} sx={{ mb: 5 }}>
                                                <Typography variant='body2' sx={{ pb: 1, color: "#757575" }}>Location</Typography>
                                                <Typography variant='body1' fontWeight="var(--font-weight-4)">{data.address}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Box>
                                )) :
                                    [1, 2].map(d => <ListItem><Skeleton variant="text" sx={{ width: "100%", height: 40 }} /></ListItem>)
                                }
                                {openAddress ?
                                    <AddAddressDrawer parentId='address' cities={cities} states={states} countries={countries} setOpenAddress={setOpenAddress} />
                                    :
                                    <Box textAlign={"center"}>
                                        <Button startIcon={<Add />} variant="outlined" onClick={openAddressTextfield}
                                            disabled={userAddressDetails?.length == 2}
                                        > ADD ADDRESS</Button>
                                    </Box>
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Card>

                </Box>
            </Box>
        </Box>
    )
}

export default ContactDetail